import React, { useEffect, useRef, useState } from "react";
import SubscribeTable from "./SubscribeTable";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { toast } from "react-toastify";
import toasterUpdate from "../utils/toasterUpdate";
import { baseUrl } from "../utils/baseUrl";
import Pagination from "../component/Pagination";

const Subcribe_Email = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [from, setFrom] = useState(1);
  const token = localStorage.getItem("token");

  const [search, setSearch] = useState("");

  const searchRef = useRef(null);

  const fetchUsers = async () => {
    const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(
        `${baseUrl}/admin/subscribed?page=${currentPage}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, data.message, "error");
        return;
      }

      setData(result.data.data);
      setLastPage(result.data.last_page);
      setFrom(result.data.from)
      toast.dismiss(toastID);
    } catch (error) {
      toasterUpdate(toastID, error, "success");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchUsers();
  };

  const searchGo = () => {
    setSearch(searchRef.current.value);
  };

  useEffect(() => {
    fetchUsers();
  }, [search]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Subscribe to emails</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    ref={searchRef}
                    className="form-control"
                    placeholder="Search by email"
                  />
                  <span
                    onClick={searchGo}
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                  >
                    Search
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    {/* <button
                      className="btn btn-primary btn-theme1"
                      type="button"
                    >
                      {" "}
                      Download{" "}
                      <img
                        src="/images/download.png"
                        alt="download"
                        style={{ marginTop: "-4px" }}
                      />{" "}
                    </button> */}
                  </div>
                </div>
                <SubscribeTable data={data} from={from} />
                <Pagination
                  handlePageChange={handlePageChange}
                  lastPage={lastPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subcribe_Email;
