import React, { useEffect } from "react";
import { useState } from "react";
import CatelougeData from "./CatelougeApi";
import CatelougeTable from "./CatelougeTable";
import { Link, useAsyncError } from "react-router-dom";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import { toast } from "react-toastify";
import Pagination from "../component/Pagination"

const Product_Catalouge = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState('');

  const [id, setId] = useState([]);

  const fetchApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/catalogues?page=${currentPage}&search=${search}`, {
      headers: {
        Authorization: token,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    toast.dismiss(toastID);
  };

  const deleteApi = async () => {
    const token = localStorage.getItem("token");

    if (id.length === 0) {
      toast.error("Select atleast one catalogue!!");
      return;
    }

    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/admin/catalogue/${id}/destroy`, {
      method: "delete",
      headers: {
        Authorization: token,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setId([]);
    toasterUpdate(toastID, result.message, "success");
    fetchApi();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchApi();
    return () => toast.dismiss();
  }, [currentPage]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="../images/Menu.png" height="20" alt="Menu" />
                  <h3>Product Catalogs</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search by product name"
                    onInput={(e) => setSearch(e.target.value)}
                  />
                  <span
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                    onClick={() => fetchApi(1)}
                  >
                    Search
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary btn-theme1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={deleteApi}
                          >
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>

                    <div className="dropdown">
                      <button
                        className="btn btn-secondary btn-theme1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Upload Catalogs
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="add" className="dropdown-item">
                            Single Upload
                          </Link>
                        </li>
                        <li>
                          <Link to="bulk" className="dropdown-item">
                            Bulk Upload
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <CatelougeTable data={data} action={{ id, setId }} />
                <Pagination handlePageChange={handlePageChange} lastPage={lastPage} currentPage={currentPage} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product_Catalouge;
