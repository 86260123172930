import React from 'react'
import CouponTable from './CouponTable'
import CouponData from './CouponApi'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import Navebar from '../navbar'

const Coupons = () => {
  const [data, setData] = useState(CouponData)
  return (
    <>
      <div className='Main-wrapper'>
        <MobileMenu />
        <div className='left_menu'>
          <div className='LeftFixedMenu'>
            <div className='row'>
              <div className='col-12'>
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className='rightSideBar2'></div>
        <div className='rightSideBar'>
          <div className='row'>
            <div className='col-12'>
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="../images/Menu.png" height="20" alt="Menu" />
                  <h3>Coupons</h3>
                </div>
                <div className="input-group my-4">
                  <input type="text" className="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                  <span className="input-group-text searchBtn" id="basic-addon2">Search</span>
                </div>





                <div className='d-flex justify-content-between'>
                  <div>
                    <div className="dropdown">
                      <button className="btn btn-secondary btn-theme1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">Delete</a></li>

                      </ul>
                    </div>
                  </div>
                  <div><Link to="add" className="btn btn-primary btn-theme1" type="button"> Add Coupon +        </Link></div>


                </div>

                <CouponTable data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Coupons
