import React from "react";
import { Link } from "react-router-dom";
import styles from "./contact.module.css";
import Moment from "react-moment";

const ContactTable = ({ data }) => {
  return (
    <>
      <div className="table table-responsive">
        <table className="table checkBoxTable ">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
              <th>Company</th>
              <th>Date</th>
            </tr>
          </thead>
          {data.map((item, index) => {
            return (
              <>
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td className={styles.td} data-title={item.description}>
                    {item.description.slice(0, 30)}
                    {""}
                    {item.description.length >= 30 && "...."}
                  </td>
                  <td>{item.company}</td>
                  <td><Moment format="ll hh:mm a">{item.created_at}</Moment></td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default ContactTable;
