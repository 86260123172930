import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import BuyerData from "./BuyerApi";
// import SellerInfo from './SellerInfo'
import MerchentTable from "./MerchentTable";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";
import toasterUpdate from "../utils/toasterUpdate";
import { Link } from "react-router-dom";
import Pagination from "../component/Pagination"


const Merchents = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const token = localStorage.getItem("token");

  const [search, setSearch] = useState("");
  const [ids, setIds] = useState([]);

  const searchRef = useRef(null);

  const fetchUsers = async () => {
    // const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(`${baseUrl}/admin/users?page=${currentPage}&search=${search}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();

      if (result.error) {
        // toasterUpdate(toastID, data.message, "error");
        toast.error(data.message);
        return;
      }

      console.log("merchants response", result);
      setData(result.data.data);
      setLastPage(result.data.last_page);
      toast.dismiss();
    } catch (error) {
      toast.error(data.message);
    }
  };

  const onDelete = async () => {
    try {
      const toastID = toast.loading("Deleting...");
      const result = await fetch(`${baseUrl}/admin/delete`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids }),
      });
      const data = await result.json();
      if (data.error) {
        toasterUpdate(toastID, data.message, "error");
        return;
      }

      toasterUpdate(toastID, data.message, "success");
      fetchUsers();
      setIds([]);
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchUsers()
  };

  const searchGo = () => {
    setSearch(searchRef.current.value);
  };

  useEffect(() => {
    fetchUsers();

    return () => toast.dismiss();
  }, [currentPage, search]);
  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Merchants List</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    ref={searchRef}
                    onInput={searchGo}
                    className="form-control"
                    placeholder="Search by name"
                  />
                  <span
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                  >
                    Search
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary btn-theme1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <button className="dropdown-item" onClick={onDelete}>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <Link
                      to={"add"}
                      className="btn btn-primary btn-theme1"
                      type="button"
                    >
                      {" "}
                      Add Merchant +{" "}
                    </Link>
                  </div>
                </div>
                <MerchentTable api={data} action={{ ids, setIds }} />
                <Pagination handlePageChange={handlePageChange} lastPage={lastPage} currentPage={currentPage} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchents;
