import React from 'react'

const LogTable = ({Data}) => {
    
  return (
    <>
        <div className='table logsTable table-responsive table-borderless'>
            <table className='table '>
                <tr>
                    <th>RFQ ID</th>
                    <th>Customer name</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th className='text-center'>Milestone</th>
                </tr>
                {Data.map((CurntElem)=>{
                    return(
                        <tr>
                            <td>{CurntElem.RFQID}</td>
                            <td>{CurntElem.CustomerName}</td>
                            <td>{CurntElem.Status}</td>
                            <td>{CurntElem.Price}</td>
                            <td><button type="button" className="btn" style={{margin:"0px auto", color:"#006FF2", width: "80px", display: "block",}}>View</button>    </td>
                        </tr>
                    )
                })}
            </table>
        </div>
    </>
  )
}

export default LogTable
