import React, { useEffect, useState } from "react";
import "./style.css";
import MenuItems from "./menuApi";
import AllMenuItems from "./AllMenuItems";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Navebar = () => {
  //can't go to other pages without login(for login )
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  function logout() {
    //localStorage.setItem("token", " ");
    localStorage.clear();
    navigate("/login");
    // window.location.reload(); //for reload page
  }

  // console.log(MenuItems);
  const [request, setrequest] = useState(MenuItems);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="leftnavbar_Outer">
            <div className="logoImg">
              <a href="">
                <img src="/images/logo.png" alt="Logo" />
              </a>
            </div>

            <ul className="menuList">
              <AllMenuItems request={request} />
              <li onClick={logout}>
                <button className="p-2 btn">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={"/images/logout-8.png"} alt={"logout.png"} />
                    </div>
                    <div className="fw-bold">Logout</div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navebar;
