import React, { useEffect, useRef } from "react";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ResponsiveContainer,
  LineChart,
  Bar,
  Line,
  XAxis,
  BarChart,
  CartesianGrid,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import TotalPurchaseTable from "../merchents/TotalPurchaseTable";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import { toast } from "react-toastify";
import { FormCheck } from "react-bootstrap";

const CompanyDetail = () => {
  const [general, setGeneral] = useState(false);
  const [account, setAccount] = useState(false);
  const [doc, setDoc] = useState([]);
  const [orders, setOrders] = useState([]);
  const [sales, setSales] = useState([]);
  const [member, setMember] = useState([]);
  const [plan, setPlan] = useState("");

  const { id } = useParams();

  const SalesData = [
    {
      id: 1,
      sale: 200,
      month: "Jan",
      thumClr: "#8DAED6",
    },
    {
      id: 2,
      sale: 240,
      month: "Feb",
      thumClr: "#084A98",
    },
    {
      id: 3,
      sale: 344,
      month: "Mar",
      thumClr: "#006FF2",
    },
    {
      id: 4,
      sale: 170,
      month: "Apr",
      thumClr: "#8DAED6",
    },
    {
      id: 5,
      sale: 399,
      month: "May",
      thumClr: "#084A98",
    },
    {
      id: 6,
      sale: 104,
      month: "Jun",
      thumClr: "#006FF2",
    },
  ];

  const [show, setShow] = useState(false);
  const checkRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/${id}/vendor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);

    setAccount(result.data);
    setGeneral(result.data.vendor);
    setDoc(result.data.doc);
    setOrders(result.data.orders);
    setSales(result.data.sales);
    setMember(result.data.member);
  };

  const changeApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/${id}/member`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ membership: plan }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    handleClose();
    toasterUpdate(toastID, result.message, "success");
    fetchApi();
  };

  const approveApi = async () => {
    const token = localStorage.getItem("token");

    const bool = checkRef.current.checked;

    const toastID = toast.loading("Loading...");
    const response = await fetch(
      `${baseUrl}/admin/${id}/vendor/${bool ? "approve" : "disapprove"}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, result.message, "success");
    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <React.Fragment>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Company Details</h3>
                </div>

                <div className="borderSetion mt-4">
                  <div className="active-popop">
                    <h2>General Information</h2>
                    <button
                      className="active"
                      variant="primary"
                      onClick={handleShow}
                    >
                      Active Membership
                    </button>
                    <div>
                      <label>Approve</label>
                      <FormCheck
                        checked={account.approved}
                        ref={checkRef}
                        type="switch"
                        onChange={approveApi}
                      />
                    </div>
                  </div>

                  {general && (
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="table table-responsive table-borderless">
                          <table className="table">
                            <tr>
                              <td>Company’s Name</td>
                              <td>{general.company}</td>
                            </tr>
                            <tr>
                              <td>Address</td>
                              <td>
                                {general.address}
                                {general.zip} {general.city} {general.state}{" "}
                                {general.country}
                              </td>
                            </tr>
                            <tr>
                              <td>GSTIN</td>
                              <td>{general.gst}</td>
                            </tr>
                            <tr>
                              <td>PAN Number</td>
                              <td>{general.pan}</td>
                            </tr>
                            <tr>
                              <td>TAN Number</td>
                              <td>{general.tan}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="table table-responsive table-borderless">
                          <table className="table">
                            <tr>
                              <td>Udhaym Aadhar No</td>
                              <td>{general.aadhaar}</td>
                            </tr>
                            <tr>
                              <td>Drug Liscense No</td>
                              <td>{general.drug_licence}</td>
                            </tr>
                            <tr>
                              <td>Explosive Liscense No</td>
                              <td>{general.explosive_licence}</td>
                            </tr>
                            <tr>
                              <td>Prohibition Excise</td>
                              <td>{general.prohibition}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="borderSetion mt-4">
                  <div className="d-flex justify-content-between">
                    <h2>Account Information</h2>
                    <Link to={`/merchants/${account.id}/edit`}><span className="fas fa-pencil"></span></Link>
                  </div>
                  {account && (
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="table table-responsive table-borderless">
                          <table className="table">
                            <tr>
                              <td>Name</td>
                              <td>{account.name}</td>
                            </tr>
                            <tr>
                              <td>Email ID</td>
                              <td>{account.email}</td>
                            </tr>
                            <tr>
                              <td>Phone</td>
                              <td>{account.phone}</td>
                            </tr>
                            <tr>
                              <td>Membership</td>
                              <td>
                                <span
                                  id={`${member && member.membership}`}
                                  className={`${member && member.membership}`}
                                >
                                  {member && member.membership}
                                </span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="table table-responsive table-borderless">
                          <table className="table">
                            <tr></tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="borderSetion mt-4">
                  <h2 className="pb-3">Documents</h2>
                  <div className="row">
                    {doc &&
                      doc.map((item, index) => (
                        <div key={index} className="col-md-3">
                          <div className="card">
                            <a
                              href={item.url}
                              target={"_blank"}
                              className="image"
                            >
                              <img
                                src={"/images/no-file.png"}
                                height="20"
                                alt="Menu"
                              />
                            </a>
                            <div className="p-3">
                              <p style={{ color: "#575757" }}>{item.label}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <ul className="nav nav-pills my-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Buyer’s{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Seller’s
                    </button>
                  </li>
                </ul>

                <div className="tab-content mt-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="row p-0">
                      <div className="col-md-6 col-12">
                        <div className="borderSetion">
                          <h4>Buyer’s Limit</h4>
                          <div className="d-flex BigNum justify-content-between mt-4">
                            <div>
                              <h5>50,000</h5>
                              <p>Sanctioned Limit</p>
                            </div>
                            <div>
                              <h5 className="text-end">1,50,000</h5>
                              <p className="text-end">Sanctioned Limit</p>
                            </div>
                          </div>
                          <div className="d-flex BigNum justify-content-between mt-2">
                            <div>
                              <h5>10,000</h5>
                              <p>Total Spend</p>
                            </div>
                            <div>
                              <h5 className="text-end">1,50,000</h5>
                              <p className="text-end">Last Spend</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="borderSetion">
                          <h4>Order Details</h4>
                          <div className="d-flex BigNum justify-content-between mt-4">
                            <div>
                              <h5>150</h5>
                              <p>Total Orders</p>
                            </div>
                            <div>
                              <h5 className="text-end">30</h5>
                              <p className="text-end">Delivered</p>
                            </div>
                          </div>
                          <div className="d-flex BigNum justify-content-between mt-2">
                            <div>
                              <h5>15,00,000</h5>
                              <p>Most Valued Order</p>
                            </div>
                            <div>
                              <h5 className="text-end">1,00,00</h5>
                              <p className="text-end">Lowest valued Order</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="borderSetion mt-4">
                          <TotalPurchaseTable
                            label={"Purchase"}
                            data={orders}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div
                          className="borderSetion"
                          style={{ height: "350px" }}
                        >
                          <h4>Seller’s Side</h4>
                          <div className="d-flex BigNum justify-content-between mt-4">
                            <div>
                              <h5>50,000</h5>
                              <p>Sanctioned Limit</p>
                            </div>
                            <div>
                              <h5 className="text-end">1,50,000</h5>
                              <p className="text-end">Sanctioned Limit</p>
                            </div>
                          </div>
                          <div className="d-flex BigNum justify-content-between mt-2">
                            <div>
                              <h5>10,000</h5>
                              <p>Total Spend</p>
                            </div>
                            <div>
                              <h5 className="text-end">1,50,000</h5>
                              <p className="text-end">Last Spend</p>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "35px" }}>
                            <div className="col-6">
                              <button className="btn btn-outline-theme w-100">
                                View Detail
                              </button>
                            </div>
                            <div className="col-6">
                              <button className="btn btn-primary btn-theme w-100">
                                Increase Limit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="loanSaleChart87 borderSetion">
                          <div
                            className="d-flex justify-content-between pb-4 px-2"
                            style={{
                              marginTop: "10px",
                              marginLeft: "14px",
                              marginRight: "14px",
                            }}
                          >
                            <h2 style={{ fontSize: "22px" }}>Total Revenue</h2>
                            <div>
                              <h5>In cr.</h5>
                            </div>
                          </div>

                          <ResponsiveContainer width="100%" aspect={2}>
                            <BarChart width={450} height={402} data={SalesData}>
                              <XAxis axisLine={false} dataKey="month" />
                              <YAxis axisLine={false} />
                              <Tooltip />
                              <Bar
                                dataKey="sale"
                                fill={SalesData[1].thumClr}
                                radius={[10, 10, 0, 0]}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="borderSetion mt-4">
                          <TotalPurchaseTable label={"Sales"} data={orders} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <MerchentTable  data={data} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  Membership popup */}
      <Modal show={show} onHide={handleClose} centered className="member-popup">
        <Modal.Header closeButton>
          <Modal.Title>Select Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-input">
            <label>Company’s Name</label>
            <p>{general && general.company}</p>
          </div>
          <div className="form-input">
            <label>Membership</label>
            <select
              className="form-select"
              required
              onChange={(e) => {
                setPlan(e.target.value);
              }}
            >
              <option>Membership</option>
              <option
                value="platinum"
                selected={member.membership == "platinum"}
              >
                Platinum
              </option>
              <option value="gold" selected={member.membership == "gold"}>
                Gold
              </option>
              <option value="silver" selected={member.membership == "silver"}>
                Silver
              </option>
              <option
                value="premium blue"
                selected={member.membership == "premium blue"}
              >
                Premium Blue
              </option>
              <option
                value="paid blue"
                selected={member.membership == "paid blue"}
              >
                Paid Blue
              </option>
              <option value="blue" selected={member.membership == "blue"}>
                Blue
              </option>
            </select>
          </div>
        </Modal.Body>

        <Button className="active" onClick={changeApi}>
          Activate
        </Button>
      </Modal>
    </React.Fragment>
  );
};

export default CompanyDetail;
