import React from "react";
import Moment from "react-moment";

const SubscribeTable = ({ data, from }) => {
  return (
    <>
      <div className="table mt-4 table-responsive">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Email </th>
              <th>Date </th>
            </tr>
          </thead>
          {data.map((item, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td>{from + index}</td>
                  <td>{item.email}</td>
                  <td><Moment format="ll - hh:mm a">{item.created_at}</Moment></td>
                  {/* <td><button type="button" className="btn btn-table float-end">View</button></td> */}
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default SubscribeTable;
