import React from 'react'

const PaymentTable = ({data}) => {
  return (
    <>
      <div className='table mt-4 table-responsive loanTable'>
        <table className='table checkBoxTable '>
            <tr>
            <th></th>
                <th>TransactionID</th>
                <th>Company</th>
                <th>Credit Limit</th>
                <th>Sanctioned Limit</th>
                <th className='text-center'>Details</th>
            </tr>   
            {data.map((CurntElem)=>{
                    return(
                        <>
                            <tr>
                                <td>
                                    <input type="checkbox" />
                                </td>
                                <td>{CurntElem.TransactionID}</td>
                                <td>{CurntElem.Company}</td>
                                <td>{CurntElem.CreditLimit}</td>
                                <td>{CurntElem.SanctionedAmount}</td>
                                <td><button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-table" style={{margin:"0px auto",width:"80px",display:"block"}}>View</button></td>
                            </tr> 
                        </>
                    )
               })} 
        </table>    
    </div>

    
    </>
  )
}

export default PaymentTable
