import React, { useEffect } from "react";
import { useState } from "react";
import MarchantListData from "./MarchantListApi";
import MarchantListTable from "./MarchantListTable";
import { Link } from "react-router-dom";
const Merchant_List = ({data}) => {

  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <div className="Marchant_outer">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fSize18 fWeight6">Merchant List</div>
              <div>
                <Link to="/merchants" className="btn btn-primary btn-theme1">
                  View All
                </Link>
              </div>
            </div>
            <MarchantListTable data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchant_List;
