import React from 'react'
import { baseUrl, hostUrl } from '../utils/baseUrl'
import { toast } from 'react-toastify';


const Download = ({ report, date }) => {

  let month = '';
  let year = '';

  if (date) {
    const newDate = new Date(date);
    month = newDate.getMonth() + 1;
    year = newDate.getFullYear();
  }

  const downloadApi = async () => {
    const token = localStorage.getItem("token");

    const response = await fetch(`${baseUrl}/admin/reports/${month}/${year}/${report}`, {
      method: "get",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });

    const contentDisposition = response.headers.get("Content-Disposition");
    const filenameMatch =
      contentDisposition && contentDisposition.match(/filename="(.+)"/);
    const filename = filenameMatch ? filenameMatch[1] : `${report}-${month}-${year}`;

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    const result = await response.json();

    if (result.error) {
      // toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }
    toast.success(result.message);
  };

  return !date ?
    (<button disabled={true} className="btn btn-table">Download</button>) :
    (<button onClick={downloadApi} className="btn btn-table">Download</button>)
}

const ReportsTable = ({ date }) => {

  return (
    <>
      <div className='table mt-4 table-responsive'>
        <table className='table table-responsive '>
          <tr className='CatelougeTable'>
            <th>Report Module</th>
            <th className='text-center'>Download</th>
          </tr>
          <tr className='CatelougeTable'>
            <td>Party Master</td>
            <td className='text-center'><Download report={'vendors'} date={date} /></td>
          </tr>
          <tr className='CatelougeTable'>
            <td>Catalogs</td>
            <td className='text-center'><Download report={'catalogs'} date={date} /></td>
          </tr>
          <tr className='CatelougeTable'>
            <td>Products</td>
            <td className='text-center'><Download report={'products'} date={date} /></td>
          </tr>
          <tr className='CatelougeTable'>
            <td>Purchases</td>
            <td className='text-center'><Download report={'purchase'} date={date} /></td>
          </tr>
          <tr className='CatelougeTable'>
            <td>Sales</td>
            <td className='text-center'><Download report={'sales'} date={date} /></td>
          </tr>
          <tr className='CatelougeTable'>
            <td>Payments</td>
            <td className='text-center'><Download report={'payments'} date={date} /></td>
          </tr>
          <tr className='CatelougeTable'>
            <td>Reciept</td>
            <td className='text-center'><Download report={'reciept'} date={date} /></td>
          </tr>
        </table>
      </div>
    </>
  )
}

export default ReportsTable
