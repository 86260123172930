import React, { useEffect, useRef } from "react";
import { useState } from "react";
import RecentTransactionsData from "./RecentTransactionsApi";
import RecentTransactionsTable from "./RecentTransactionsTable";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import toasterUpdate from "../utils/toasterUpdate";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { useParams } from "react-router-dom";

const LoanDetail = () => {
  const [RecentTransData, setRecentTransData] = useState(
    RecentTransactionsData
  );

  const [data, setData] = useState({});
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("token");

  const [sanction, setSanction] = useState(0);
  const closeRef = useRef();

  const { id } = useParams();

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(
        `${baseUrl}/admin/wallet/${id}/show?page=${currentPage}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      setData(result.data.data);
      setRecords(result.data.records);
      //   setLastPage(result.data.records.last_page);
      toast.dismiss(toastID);
    } catch (error) {
      toasterUpdate(toastID, error, "error");
    }
  };

  const sanctionApi = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    try {
      const response = await fetch(`${baseUrl}/admin/wallet/${id}/sanction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sanctioned_amount: sanction }),
      });
      const result = await response.json();

      console.log(result);

      if (result.error) {
        closeRef.current.click();
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      fetchApi();
      closeRef.current.click();
      toasterUpdate(toastID, result.message, "success");
    } catch (error) {
      toasterUpdate(toastID, error, "error");
    }
  };

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Loan Details</h3>
                </div>

                <div className="">
                  <div className="row mt-5">
                    <div className="col-md-3">
                      <p className="fWeight6 mb-1">Company’s Name</p>
                    </div>
                    <div className="col-md-9">
                      <p className=" mb-1">{data.vendor?.company}</p>
                    </div>
                    <div className="col-md-3">
                      <p className="fWeight6  mb-1">Location</p>
                    </div>
                    <div className="col-md-9">
                      <p className=" mb-1">{data.vendor?.address}</p>
                    </div>
                    {/* <div className="col-md-3">
                      <p className="fWeight6  mb-1">Last Request</p>
                    </div>
                    <div className="col-md-9">
                      <p className=" mb-1">20/8/2022</p>
                    </div> */}
                  </div>
                </div>

                <div className="row BuyerSellerSideOuter">
                  <div className="col-md-12 col-12">
                    <div className="borderSetion">
                      <h4>Vendor</h4>
                      <div className="d-flex BigNum justify-content-between mt-4">
                        <div>
                          <h5>{data.wallet?.sanctioned_amount || 0}</h5>
                          <p>Sanctioned Limit</p>
                        </div>
                        <div>
                          <h5 className="text-end">
                            {data.wallet?.balance || 0}
                          </h5>
                          <p className="text-end">Credit Limit</p>
                        </div>
                      </div>
                      <div className="d-flex BigNum justify-content-between mt-2">
                        <div>
                          <h5>
                            {data.wallet?.sanctioned_amount -
                              data.wallet?.balance}
                          </h5>
                          <p>Total Spend</p>
                        </div>
                        <div>
                          <h5 className="text-end">
                            {data.wallet?.lastTransaction?.amount || 0}
                          </h5>
                          <p className="text-end">Last Spend</p>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "35px" }}>
                        <div className="col-6">
                          <button
                            className="btn btn-primary btn-theme w-100"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Increase Limit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-12">
                    <div className="borderSetion">
                      <h4>Seller’s Side</h4>
                      <div className="d-flex BigNum justify-content-between mt-4">
                        <div>
                          <h5>50,000</h5>
                          <p>Sanctioned Limit</p>
                        </div>
                        <div>
                          <h5 className="text-end">1,50,000</h5>
                          <p className="text-end">Credit Limit</p>
                        </div>
                      </div>
                      <div className="d-flex BigNum justify-content-between mt-2">
                        <div>
                          <h5>10,000</h5>
                          <p>Total Sales</p>
                        </div>
                        <div>
                          <h5 className="text-end">1,50,000</h5>
                          <p className="text-end">Last Sales</p>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "35px" }}>
                        <div className="col-6">
                          <button className="btn btn-outline-theme w-100">
                            View Detail
                          </button>
                        </div>
                        <div className="col-6">
                          <button className="btn btn-primary btn-theme w-100">
                            Increase Limit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <h3 className="fSize18 mt-5">Sanction Requests</h3>

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="table table-responsive">
                      <table className="table table-responsive">
                        <thead>
                          <tr className="text-center">
                            <th>ID</th>
                            <th>User ID</th>
                            <th>Amount</th>
                            <th>Sanctioned amount</th>
                            <th>Sanctioned limit</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {records.map((item, index) => {
                            return (
                              <tr className="text-center" key={index}>
                                <td>{item.id}</td>
                                <td>{item.vendor_id}</td>
                                <td>{item.amount}</td>
                                <td>{item.sanctioned_amount}</td>
                                <td>{item.sanctioned_amount}</td>
                                <td>{item.created_at}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* <OrderTable data={data} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade recentTrsModal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <form onSubmit={sanctionApi}>
              <div className="modal-body">
                <h4 className="ms-0 pb-4">Request Approval</h4>
                <div className="mb-3">
                  <label className="form-label">Sanctioned Amount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    onInput={(e) => {
                      setSanction(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer" style={{ borderTop: "unset" }}>
                <button
                  type="button"
                  className="btn btn-outline-theme"
                  data-bs-dismiss="modal"
                  ref={closeRef}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary btn-theme">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanDetail;
