import { toast } from "react-toastify";

function toasterUpdate(toastID, data, type) {
  toast.update(toastID, {
    render: data,
    type,
    autoClose: 1500,
    isLoading: false,
  });
}

export default toasterUpdate;
