import React, { useEffect, useRef, useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import { useNavigate } from "react-router-dom";

const BulkUpload = () => {
  const [file, setFile] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const baseRef = useRef(null);

  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const allowedExtensions = ["xls", "xlsx", "csv"];

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        if (file.size > 4000000) {
          setFile("");
          baseRef.current.value = null;
          toast.error("Please select a file under 4 MB.");
        } else {
          setFile(file);
        }
      };
    } else {
      setFile("");
      baseRef.current.value = null;
      toast.error("Invalid file format. Please upload an Excel file.");
    }
  };

  const downloadApi = async () => {
    const token = localStorage.getItem("token");

    const response = await fetch(`${baseUrl}/admin/catalogues/sample`, {
      method: "get",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });

    const contentDisposition = response.headers.get("Content-Disposition");
    const filenameMatch =
      contentDisposition && contentDisposition.match(/filename="(.+)"/);
    const filename = filenameMatch ? filenameMatch[1] : "sample-download";

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    const result = await response.json();

    setIsLoading(false);

    if (result.error) {
      // toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }
    toast.success(result.message);
  };

  const createApi = async () => {
    const token = localStorage.getItem("token");
    const toastID = toast.loading("Processing...");
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(`${baseUrl}/admin/catalogues/import`, {
      method: "post",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    const result = await response.json();

    setIsLoading(false);

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");
  };

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="../images/Menu.png" height="20" alt="Menu" />
                  <h3>Product Catalogs</h3>
                </div>

                <div className="borderSetion mt-4">
                  <div className="d-flex justify-content-between">
                    <h2 className="fSize16">Bulk Upload Catalogs</h2>
                    <button onClick={downloadApi} className="btn btn-dark">
                      Download Sample
                    </button>
                  </div>

                  <div className="mt-5">
                    <p>
                      <b>Step 1:</b> Prepare Your Data Before you begin, make
                      sure your data is organized in an Excel file with the
                      required columns. Download our template file{" "}
                      <button
                        onClick={downloadApi}
                        className="btn btn-sm text-primary fw-bold border border-2 rounded-0"
                      >
                        here
                      </button>{" "}
                      to ensure the correct format.
                    </p>
                    <p>
                      <b>Step 2:</b> Fill in the Data Open the downloaded
                      template in Excel and populate it with your data. Ensure
                      that each row corresponds to a single record and that the
                      columns are correctly labeled.
                    </p>
                    <p>
                      <b>Step 3:</b> Save the File Save your Excel file in .xls
                      or .xlsx format. Ensure the file is not
                      password-protected, as our system won't be able to process
                      password-protected files.
                    </p>
                  </div>

                  <div className="mt-4">
                    <div className="row mt-3">
                      <div className="col-11">
                        <div className="mb-4">
                          <label for="formFile" className="form-label">
                            <small className="text-danger">{`accepts only excel format *`}</small>
                          </label>
                          <input
                            ref={baseRef}
                            className="form-control"
                            name="file"
                            type="file"
                            accept=".csv, .xlsx, .xls"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                      <div className="col-1">
                        <button
                          style={{ marginTop: "32px" }}
                          type="button"
                          onClick={createApi}
                          disabled={isLoading}
                          className="btn btn-info"
                        >
                          <img src="/images/upload.png" alt="upload" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkUpload;
