import React, { useState } from 'react'
import PaymentData from './PaymentTableApi'
import PaymentTable from './PaymentTable'
import MobileMenu from '../MobileMenu/MobileMenu';
import Navebar from '../navbar';




const Payments = () => {
  const [data, setData] = useState(PaymentData);
  return (
    <>
      <div className='Main-wrapper'>
        <MobileMenu />
        <div className='left_menu'>
          <div className='LeftFixedMenu'>
            <div className='row'>
              <div className='col-12'>
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className='rightSideBar2'></div>
        <div className='rightSideBar'>
          <div className='row'>
            <div className='col-12'>
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="../images/Menu.png" height="20" alt="Menu" />
                  <h3>Payment</h3>
                </div>
                <div className="input-group my-4">
                  <input type="text" className="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                  <span className="input-group-text searchBtn" id="basic-addon2">Search</span>
                </div>


                <div className=''>
                  <div className=''>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Buyers </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Sellers</button>
                      </li>
                    </ul>
                  </div>
                </div>


                {/* <div className='d-flex justify-content-between'>
                  <div>
                    <div className="dropdown">
                      <button className="btn btn-secondary btn-theme1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">Edit</a></li>
                        <li><a className="dropdown-item" href="#">Delete</a></li>

                      </ul>
                    </div>
                  </div>


                </div> */}
                <div className="tab-content mt-4" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                    <PaymentTable data={data} />
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                    <PaymentTable data={data} />
                  </div>
                </div>
                {/* <OrderTable data={data} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade PopUpTanls88" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">

            <div className="modal-body">


              <h3 className=' ms-0'>Sanctioned Amount</h3>
              <h1>50,000</h1>
              <div className='table responsive-table paymentPoupBx table-borderless'>
                <table className='table'>
                  <tr>
                    <td>Transaction ID</td>
                    <td>2002452</td>
                  </tr>
                  <tr>
                    <td>Transaction Date</td>
                    <td>20/8/2022</td>
                  </tr>
                  <tr>
                    <td>Company Name</td>
                    <td>Shakti Trader</td>
                  </tr>
                  <tr>
                    <td>Company Credit Limit</td>
                    <td>150,000</td>
                  </tr>
                  <tr>
                    <td>Company Location</td>
                    <td>Delhi</td>
                  </tr>
                  <tr>
                    <td>Info</td>
                    <td>Info 1</td>
                  </tr>
                  <tr>
                    <td>Info</td>
                    <td>Info 2</td>
                  </tr>
                </table>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-theme" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary btn-theme">Sacntioned Money</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payments
