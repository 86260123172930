import React, { useEffect, useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";

const CatelougeDetailView = () => {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchApi = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/catalogue/${id}/show`, {
      headers: {
        Authorization: token,
      },
    });

    const result = await response.json();

    if (result.error) {
      setIsLoading(false);

      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }
    setData(() => result.data);
    setIsLoading(false);
    toasterUpdate(toastID, result.message, "success");
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Product Details</h3>
                </div>
              </div>

              <div className="borderSetion mt-4 catalog_detail">
                <h2 className="fSize16">General Information</h2>
                <div className="row mt-3">
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless  viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Product Name</td>
                          <td>{data.name}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Form factor</td>
                          <td>{data.state || "---"}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless  viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Category</td>
                          <td className="text-left">
                            {data.category_id?.parent || "null"}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless  viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Sub Category</td>
                          <td>{data.category_id?.name || "null"}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless  viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Hsn Code</td>
                          <td>{data.hsn || "---"}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Cas No</td>
                          <td>{data.cas_no || "---"}</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="d-flex justify-content-start">
                      <p className="me-5" style={{fontWeight:"500"}}>Description</p>
                      <p>{data.description || "----"}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="borderSetion mt-4">
                <h2 className="fSize16">Price Information</h2>
                <div className="row mt-3">
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless  viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Product Price</td>
                          <td>500.00</td>
                        </tr>
                        <tr>
                          <td>Logistics Gst</td>
                          <td>18%</td>
                        </tr>
                        <tr>
                          <td>Landed Price</td>
                          <td>450.00</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table  table-borderless viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Product Gst</td>
                          <td>18%</td>
                        </tr>
                        <tr>
                          <td>Expected Price</td>
                          <td>400.00</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="borderSetion mt-4">
                <h2 className="fSize16">Inventory Information</h2>
                <div className="row mt-3">
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless  viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>SKU</td>
                          <td>JGDSG52DJBD</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table  table-borderless viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Stock Availability</td>
                          <td>In Stock</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <p>
                    <b>Inventory Management</b>{" "}
                    <span style={{ marginLeft: "20px" }}>.....</span>
                  </p>
                </div>
  </div>*/}
              <div className="borderSetion mt-4">
                <h2 className="fSize16">Product Images</h2>
                <div className="row mt-3">
                  <div className="col-md-4 col-12 proBigImg">
                    <img
                      src={data.url ? data.url : "/images/no-image.png"}
                      alt={`${data.name} product thumbnail`}
                      onError={(e) => {
                        e.target.src = "/images/no-image.png";
                      }}
                    />
                  </div>
                  <div className="col-md-8 col-12">
                    <div className="row">
                      {data.additional_images &&
                        data.additional_images.map((item) => {
                          return (
                            <div className="col-md-4 proSmlImg" key={item.path}>
                              <img
                                src={
                                  item.url ? item.url : "/images/no-image.png"
                                }
                                alt={`${data.name} product additional images`}
                                onError={(e) => {
                                  e.target.src = "/images/no-image.png";
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="borderSetion mt-4">
                <h2 className="fSize16">Documents</h2>
                <div className="row mt-3">
                  <div className="col-md-8 col-12">
                    <div className="row">
                      {data.documents &&
                        data.documents.map((item) => {
                          return (
                            <div className="col-md-4 proSmlImg" key={item.path}>
                              <a href={item.url} target="_blank">
                                <img
                                  src={"/images/no-file.png"}
                                  alt={`${data.name} document`}
                                  className="preview"
                                />
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="borderSetion mt-4">
                <h2 className="fSize16">SEO Information</h2>
                <div className="row mt-3">
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless viewDeatlTable ">
                      <table className="table">
                        <tr>
                          <td>URL</td>
                          <td>www.google/facebook</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table  table-borderless">
                      <table className="table">
                        <tr>
                          <td>Meta Title</td>
                          <td>Buy Camlin 50 mg super glue </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="table responsive-table  table-borderless ">
                      <table className="table">
                        <tr>
                          <td style={{ verticalAlign: "baseline" }}>
                            Meta Description
                          </td>
                          <td>
                            <p
                              style={{
                                marginBottom: "0",
                                padding: "0",
                                color: "blue",
                                fontWeight: "600",
                              }}
                            >
                              Buy Camlin 50 mg super glue{" "}
                            </p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc vulputate libero et velit interdum, ac
                            aliquet odio mattis. Class aptent taciti sociosqu ad
                            litora torquent per conubia nostra, per inceptos
                            himenaeos. Curabitur tempus urna at turpis
                            condimentum lobortis.
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                            </div>*/}
              {/*<div className="borderSetion mt-4">
                <h2 className="fSize16">Attributes</h2>
                <div className="row mt-3">
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table table-borderless viewDeatlTable ">
                      <table className="table">
                        <tr>
                          <td>Attributes</td>
                          <td>.....</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="table responsive-table  table-borderless viewDeatlTable">
                      <table className="table">
                        <tr>
                          <td>Value</td>
                          <td>....</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                          </div>*/}
              <div className="mt-5"></div>
              <Link
                disabled={isLoading}
                to={`/catalogs/${id}/edit`}
                className="btn float-end btn-outline-theme"
                style={{ width: "120px" }}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatelougeDetailView;
