import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { baseUrl } from "../utils/baseUrl";
const Login = () => {
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    let item = { email, password };
    const toastID = toast.loading("Trying to log in..");

    const response = await fetch(`${baseUrl}/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();

    if (data.error) {
      toast.update(toastID, {
        render: data.message,
        type: "error",
        isLoading: false,
        autoClose:1500
      });
      return;
    }
    toast.update(toastID, {
      render: data.message,
      type: "success",
      isLoading: false,
      autoClose:1500
    });
    localStorage.setItem("admin", JSON.stringify(data.data.user));
    localStorage.setItem("token", data.data.token);
    navigate("/");
  };

  const isLoggedIn = () => {
    let check = localStorage.getItem("token");

    if (check && true) {
      navigate("/");
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, [navigate]);

  return (
    <>
      <div
        style={{ height: "100vh" }}
        className="container w-100 d-flex justify-content-center align-items-center"
      >
        <form onSubmit={login} className="shadow p-5">
          <div className="mb-4">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id=""
              placeholder="Email"
              value={email}
              onChange={(e) => SetEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              id=""
              placeholder="Password"
              value={password}
              onChange={(e) => SetPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mx-auto d-block">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
