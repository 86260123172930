import React, { useEffect, useMemo } from "react";
import ContactTable from "./ContactTable";
import { useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";
import toasterUpdate from "../utils/toasterUpdate";
import Pagination from "../component/Pagination";

const Contact = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const fetchApi = async () => {
    const toastID = toast.loading("Processing...");
    const response = await fetch(
      `${baseUrl}/admin/contacts?page=${currentPage}&search=${search}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return [];
    }

    toast.dismiss(toastID);
    setLastPage(result.data.last_page);

    return result.data.data;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useMemo(async () => {
    const data = await fetchApi();
    setData(data);
  }, [currentPage, search]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Queries</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                    onClick={fetchApi}
                  >
                    Search
                  </span>
                </div>

                <div className="tab-content mt-3" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <ContactTable data={data} />
                    <Pagination
                      handlePageChange={handlePageChange}
                      lastPage={lastPage}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
                {/* <MerchentTable  data={data} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
