import React, { useEffect, useRef, useState } from "react";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";

const OrderDetail = () => {
  const [data, setData] = useState({});

  const logRef = useRef(null);

  const [orderStatus, setOrderStatus] = useState("order accepted")

  const [status, setStatus] = useState({
    order: false,
    fabrication: false,
    transit: false,
    delivered: false,
    qc: false,
  });

  const { id } = useParams();
  const fetchApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/order/${id}/show`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setData(() => result.data);

    setStatus((prev) => {
      switch (result.data.order_status) {
        case "order accepted":
          return { ...prev, order: true };
          break;
        case "in fabrication":
          return { ...prev, order: true, fabrication: true };
          break;
        case "in transit":
          return { ...prev, order: true, fabrication: true, transit: true };
          break;

        case "delivered":
          return {
            ...prev,
            order: true,
            fabrication: true,
            transit: true,
            delivered: true,
          };
          break;

        case "qc accepted":
          return {
            ...prev,
            order: true,
            fabrication: true,
            transit: true,
            delivered: true,
            qc: true,
          };
          break;
        default:
          return { ...prev };
          break;
      }
    });

    toast.dismiss(toastID);
  };

  const logApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Updating...");
    const response = await fetch(`${baseUrl}/admin/order/${id}/status`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_status: orderStatus,
        log: logRef.current.value,
      }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    logRef.current.value = "";
    toasterUpdate(toastID, result.message, "success");

    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Order’s Details</h3>
                </div>
              </div>

              <div className="row mt-5">
                <div className="d-flex justify-content-between">
                  <h3 className="fSize20">Order & Account Information</h3>
                </div>
                <hr />
                <div className="col-md-6 col-12">
                  <div className="table table-responsive table-borderless orderTailsTable">
                    <table className="table">
                      <tr>
                        <td className="maxwtd">Order ID</td>
                        <td>{data.order_id}</td>
                      </tr>
                      <tr>
                        <td className="maxwtd">Customer Name</td>
                        <td className="text-capitalize">{data.buyer?.name}</td>
                      </tr>
                      <tr>
                        <td className="maxwtd">Company Detail</td>
                        <td className="text-capitalize">
                          <Link to={`/merchants/${data.buyer?.id}/detail`} className="p-0">
                            {data.buyer?.vendor.company}
                          </Link>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="table table-responsive table-borderless orderTailsTable">
                    <table className="table">
                      <tr>
                        <td className="maxwtd">Order Date</td>
                        <td><Moment className="p-0" format="ll - hh:mm a">{data.created_at}</Moment></td>
                      </tr>
                      <tr>
                        <td className="maxwtd">Order Status</td>
                        <td className="text-capitalize">{data.order_status}</td>
                      </tr>
                      <tr>
                        <td className="maxwtd">Order Type</td>
                        <td className="text-capitalize">{data.order_type}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="table table-responsive table-borderless orderTailsTable">
                    <table className="table">
                      <tr>
                        <td className="maxwtd">Payment method</td>
                        <td className="text-capitalize">{data.payment_method}</td>
                      </tr>
                      <tr>
                        <td className="maxwtd">Payment Mode</td>
                        <td className="text-capitalize">{data.payment_mode}</td>
                      </tr>
                      <tr>
                        <td className="maxwtd">Payment Reference</td>
                        <td>{data.payment_mode && data.payment_reference}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row borderSetion">
                <div className="d-flex justify-content-between mb-4">
                  <h3 className="fSize20">Delivery Update</h3>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className={`PackagingCir ${status.order && "statusGreen"}`}
                  >
                    <img src="/images/status/Vector1.png" alt="status" />
                  </div>
                  <div
                    className={`Line1 ${status.order && "statusGreen"}`}
                  ></div>
                  <div
                    className={`ShippedCir ${status.fabrication && "statusGreen"
                      }`}
                  >
                    <img src="/images/status/Vector2.png" alt="status" />
                  </div>
                  <div
                    className={`Line2 ${status.fabrication && "statusGreen"}`}
                  ></div>
                  <div
                    className={`InTransitCir ${status.transit && "statusGreen"
                      }`}
                  >
                    <img src="/images/status/Vector3.png" alt="status" />
                  </div>
                  <div
                    className={`Line3 ${status.transit && "statusGreen"}`}
                  ></div>
                  <div
                    className={`DeliveredCir ${status.delivered && "statusGreen"
                      }`}
                  >
                    <img src="/images/status/Vector4.png" alt="status" />
                  </div>
                  <div
                    className={`Line3 ${status.delivered && "statusGreen"}`}
                  ></div>
                  <div className={`DeliveredCir ${status.qc && "statusGreen"}`}>
                    <img src="/images/qc.png" alt="status" />
                  </div>
                </div>
                <div
                  className=" d-flex justify-content-between trns6567"
                  style={{ marginTop: "20px" }}
                >
                  <div className="">
                    <p>Order Accepted</p>
                    <p></p>
                  </div>
                  <div className="">
                    <p>In Production</p>
                    <p></p>
                  </div>
                  <div className="">
                    <p>In Transit</p>
                    <p></p>
                  </div>
                  <div className="">
                    <p>Delivered</p>
                    <p></p>
                  </div>
                  <div className="">
                    <p>Qc Accepted</p>
                    <p></p>
                  </div>
                </div>
                <h3 style={{ fontSize: "20px", marginTop: "40px" }}>Logs</h3>
                <div className="table table-borderless UpdattableStatus">
                  <table className="table">
                    {data.logs &&
                      data.logs.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.log}</td>
                            <td>
                              <Moment format="ll">{item.created_at}</Moment>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary btn-theme float-end"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Update Status
                  </button>
                </div>

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{ border: "none", paddingBottom: "0px" }}
                      >
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                          Status Update
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Status
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setOrderStatus(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option selected={data.order_status == "order accepted"} value={"order accepted"}>
                              Order Accepted
                            </option>
                            <option selected={data.order_status == "in fabrication"} value="in fabrication">
                              In Production
                            </option>
                            <option selected={data.order_status == "in transit"} value="in transit">In Transit</option>
                            <option selected={data.order_status == "delivered"} value="delivered">Delivered</option>
                            <option selected={data.order_status == "qc accepted"} value="qc accepted">Qc Accepted</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Logs
                          </label>
                          <textarea
                            ref={logRef}
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <div
                        className="modal-footer"
                        style={{ border: "none", paddingTop: "0px" }}
                      >
                        <button type="button" onClick={logApi} className="btn btn-primary theme-btn">
                          Update Status
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className="row mt-5">
                <div className="d-flex justify-content-between">
                  <h3 className="fSize20 mb-0">Payment Type</h3>
                  <h3 className="fSize20 mb-0 theme-color">Milestone</h3>
                </div>
                <div className="col-12">
                  <hr />
                  <div className="table table-borderless table-responsive advncPaymTable">
                    <table className="table">
                      <tr>
                        <th>Delivery Date </th>
                        <th>Percentage</th>
                        <th>Payment</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td>20/8/2022 </td>
                        <td>10%</td>
                        <td>50,000</td>
                        <td>
                          <button className="btn theme-color">Completed</button>
                        </td>
                      </tr>
                      <tr>
                        <td>20/9/2022 </td>
                        <td>50%</td>
                        <td>1,50,000</td>
                        <td>
                          <button className="btn theme-color">Notify</button>
                        </td>
                      </tr>
                      <tr>
                        <td>20/11/2022 </td>
                        <td>30%</td>
                        <td>70,000</td>
                        <td>
                          <button className="btn theme-color">Notify</button>
                        </td>
                      </tr>
                    </table>
                    <h4 style={{ fontSize: "19px", fontWeight: "500" }}>
                      Payment Documents
                    </h4>
                    <p>
                      <span>
                        <img
                          src="/images/Document2.png"
                          height="38"
                          style={{ paddingLeft: "0px" }}
                        />
                      </span>
                      <a href="">Payment</a>
                      <span>
                        <img
                          src="/images/Document2.png"
                          height="38"
                          style={{ marginLeft: "40px" }}
                        />
                      </span>
                      <a href="">Payment2</a>
                    </p>

                    <h4
                      style={{
                        fontSize: "19px",
                        fontWeight: "500",
                        marginTop: "30px",
                      }}
                    >
                      Payment Reference Number
                    </h4>
                    <p style={{ marginTop: "20px", fontWeight: "400" }}>
                      1. Transaction ID : 4235567
                    </p>
                    <p style={{ fontWeight: "400" }}>
                      2. Reference Number : 4288989
                    </p>
                  </div>
                </div>
                    </div>*/}

              <div
                className="accordion OrderDetailsAccordion"
                id="accordionPanelsStayOpenExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      className="accordion-button fSize20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Address Information
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-4  mb-2 shipDtl_outer">
                          <p className="fSize18">Billing Address</p>
                          <div className="shipDtl mt-4  theme-color fWeight6">
                            <p>
                              <span>{data.billing_address}</span>
                            </p>
                            <p>
                              <span>{data.billing_state}</span>
                            </p>
                            <p>
                              <span>Pin code {data.billing_zip}</span>{" "}
                            </p>
                            <p>
                              <span>{data.billing_country}</span>{" "}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4  mb-2 shipDtl_outer">
                          <p className="fSize18">Shipping Address</p>
                          <div className="shipDtl mt-4 theme-color fWeight6">
                            <p>{data.shipping_address}</p>
                            <p>{data.shipping_state}</p>
                            <p>Pin code {data.shipping_zip}</p>
                            <p>{data.shipping_country}</p>
                          </div>
                        </div>

                        {data.delivery_type == "pickup" && (
                          <div className="col-md-4  mb-2 shipDtl_outer">
                            <p className="fSize18">Pickup Address</p>
                            <div className="shipDtl mt-4 theme-color fWeight6">
                              <p>{data.deliver_from_address}</p>
                              <p>{data.deliver_from_state}</p>
                              <p>Pin code {data.deliver_from_zip}</p>
                              <p>{data.deliver_from_country}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button
                      className="accordion-button collapsed fSize20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Items Ordered
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body">
                      <div className="table addresTable table-responsive">
                        <table className="table table-responsive">
                          <tr>
                            <th style={{ textAlign: "left" }}>Product</th>
                            <th>Unit Price </th>
                            <th>Quantity</th>
                            <th>GST</th>
                            <th className="text-end">Total</th>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>
                              <p className="text-capitalize">{data.product?.name}</p>
                              <p>{data.product?.catalog.category_id?.name}</p>
                            </th>
                            <th>{data.price}</th>
                            <th>{data.quantity}</th>
                            <th>{Math.abs(data.total - data.price)}</th>
                            <th className="text-end">{data.total}</th>
                          </tr>
                        </table>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between theme-bg-color-gray p-2 align-items-center rounded ">
                            <span>Invoice Release</span>
                            <button className="btn theme-btn-green">Release</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                    <button
                      className="accordion-button collapsed fSize20 fWeight6"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Documents Upload
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div className="accordion-body">
                      <div className=" row">
                        <div className="col-md-10 mb-3">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                        <div className="col-md-2 mb-3 ">
                          <button
                            className="btn btn-theme w-100"
                            style={{ color: "white" }}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
