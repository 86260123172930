const CouponData = [
    {
        id:1,
        Code:202523,
        Type:"Percentage",
        Amount:"10%",
        Used:5,
        Status:"Active"
    },
    {
        id:2,
        Code:202523,
        Type:"Percentage",
        Amount:"10%",
        Used:5,
        Status:"Active"
    },
    {
        id:3,
        Code:202523,
        Type:"Percentage",
        Amount:"10%",
        Used:11,
        Status:"Expired"
    },{
        id:4,
        Code:202523,
        Type:"Percentage",
        Amount:"10%",
        Used:2,
        Status:"Active"
    },
    {
        id:5,
        Code:202523,
        Type:"Percentage",
        Amount:"10%",
        Used:5,
        Status:"Active"
    },
    {
        id:6,
        Code:202523,
        Type:"Percentage",
        Amount:"10%",
        Used:5,
        Status:"Expired"
    }
]
export default CouponData