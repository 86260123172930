import React, { useEffect } from "react";
import { useState } from "react";
import OrderDataApi from "./orderTableApi";
import OrderTable from "./OrderTable";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { toast } from "react-toastify";
import toasterUpdate from "../utils/toasterUpdate";
import { baseUrl } from "../utils/baseUrl";
import Pagination from "../component/Pagination"

const Orders = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [search, setSearch] = useState('');

  const [filteredOrders, setFilteredOrders] = useState([]);

  const fetchApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/orders?page=${currentPage}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    console.log("result orders_____________", result.data);

    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    toast.dismiss(toastID);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);

    const filtered = data.filter((item) =>
      item.order_id_counter.toString().toLowerCase().includes(value.toLowerCase()) ||
      item.order_id.toString().toLowerCase().includes(value.toLowerCase()) ||
      item.product?.name.toLowerCase().includes(value.toLowerCase()) ||
      item.seller?.vendor.company.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, [currentPage]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12"></div>
            <div className="row topImgTTL">
              <div className="col-12 d-flex align-items-center pageTTL">
                <img src="../images/Menu.png" height="20" alt="Menu" />
                <h3>Orders list</h3>
              </div>
              <div className="input-group my-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="search by product name, order id, merchant name"
                  onInput={handleSearch}
                />
                <span className="input-group-text searchBtn" id="basic-addon2">
                  Search
                </span>
              </div>

              {/*<div className="d-flex justify-content-between">
                <div>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary btn-theme1 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Actions
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <button className="btn btn-primary btn-theme1" type="button">
                    {" "}
                    Create Order +{" "}
                  </button>
                </div>
  </div>*/}
              {
                search.length === 0 ? (<OrderTable data={data} />) : (<OrderTable data={filteredOrders} />)
              }
              <Pagination handlePageChange={handlePageChange} lastPage={lastPage} currentPage={currentPage} />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
