import React from 'react'

const Card = ({ data }) => {
    return (
        <>
            <div className='DashCard-Outer'>
                {data.map((CurntElm) => {
                    return (
                        <>
                            <div className='DashCard-inner'>
                                <div className=' d-flex align-items-center'>
                                    <div className='imagOuteCircle' id={CurntElm.CirClr}>
                                        <img className={CurntElm.id} src={CurntElm.img} alt={CurntElm.name} />
                                    </div>
                                    <h2>{CurntElm.name}</h2>
                                </div>
                                <div className=' d-flex align-items-center mt-4'>

                                    <h3 className='mb-0'>{CurntElm.num}</h3>
                                    <p className={CurntElm.upDown}>{CurntElm.status}</p>
                                </div>
                            </div>

                        </>
                    )
                })}
            </div>
        </>
    )
}

export default Card
