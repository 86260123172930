import React, { useEffect, useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { Link, useNavigate } from "react-router-dom";
import CategoryTable from "./CategoryTable";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import Pagination from "../component/Pagination";

const PdtCategory = () => {
  const token = localStorage.getItem("token");

  const [slug, SetSlug] = useState("");
  const [parent, SetParent] = useState("");

  const [show, setShow] = useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  //const navigate = useNavigate();

  const fetchApi = async () => {
    const toastID = toast.loading("loading...");
    const response = await fetch(
      `${baseUrl}/admin/categories?page=${currentPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setData(result.data.data);
    setLastPage(result.data.last_page);
    toast.dismiss(toastID);
  };

  const addcategory = async (e) => {
    e.preventDefault();
    let item = { slug, parent };

    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/admin/category`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();

    if (data.error) {
      toast.update(toastID, {
        render: data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      return;
    }

    fetchApi();
    closeModal();

    toast.update(toastID, {
      render: data.message,
      type: "success",
      isLoading: false,
      autoClose: 1500,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Product Catalogs</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <span
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                  >
                    Search
                  </span>
                </div>

                <div className="d-flex justify-content-end">
                  <div>
                    <Link
                      onClick={openModal}
                      className="btn btn-primary btn-theme1"
                    >
                      {" "}
                      Add Category +{" "}
                    </Link>
                  </div>
                </div>
                <CategoryTable data={data} reload={fetchApi} />
                <Pagination handlePageChange={handlePageChange} lastPage={lastPage}  currentPage={currentPage} />

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* popup for add category */}
      {show && (
        <div
          className="modal fade pdtCat show"
          id="exampleModal"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.45)" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <form onSubmit={addcategory}>
                <div className="modal-body">
                  <h4 className=" ms-0 pb-4">Create Category</h4>
                  <div className="mb-3 form-group">
                    <label for="pass" className="form-label">
                      Parent Category
                    </label>
                    <select
                      aria-label="Default select example"
                      required
                      onChange={(e) => {
                        SetParent(e.target.value);
                      }}
                    >
                      <option value={``}>Select Parent</option>
                      <option value={`api`}>Api</option>
                      <option value={`chemicals`}>Chemicals</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Product Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="..."
                      value={slug}
                      onChange={(e) => {
                        SetSlug(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer" style={{ borderTop: "unset" }}>
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary btn-theme">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PdtCategory;
