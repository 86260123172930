import React, { useEffect, useRef, useState } from "react";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";
import toasterUpdate from "../utils/toasterUpdate";

const TicketEdit = () => {
  //for Accordion
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState({});
  const [enable, setEnable] = useState(true);

  const [message, setMessage] = useState("");
  const [attach, setAttach] = useState("");
  const replyRef = useRef(null);
  const attachRef = useRef(null);

  const { id } = useParams();

  const token = localStorage.getItem("token");

  const fetchApi = async () => {
    const tokenID = toast.loading("Loading...");

    replyRef.current.value = "";
    attachRef.current.value = "";
    const response = await fetch(`${baseUrl}/admin/tickets/${id}/show`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(tokenID, result.message, "error");
      return;
    }

    toast.dismiss(tokenID);
    setData(result.data);
    setEnable(result.data.status == "resolved");
  };

  const respondApi = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("message", message);
    formData.append("attach", attach);
    const toastID = toast.loading("Sending...");

    setEnable(true);

    const response = await fetch(`${baseUrl}/admin/tickets/${id}/respond`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();

    if (result.error) {
      setEnable(false);
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    fetchApi();
  };

  const resolveApi = async () => {
    const tokenID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/admin/tickets/${id}/resolve`, {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    console.log("ticket detail response_________", result);
    if (result.error) {
      toasterUpdate(tokenID, result.message, "error");
      return;
    }

    toasterUpdate(tokenID, result.message, "success");
    fetchApi();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/webp") ||
      (file && file.type === "image/jpg") ||
      (file && file.type === "application/pdf")
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 400000) {
          setAttach("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 400kb."
          );
        } else {
          setAttach(file);
        }
      };
    } else {
      setAttach("");
      attachRef.current.value = null;
      toast.error("Please select a valid image or pdf format.");
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div className="Main-wrapper">
      <MobileMenu />
      <div className="left_menu">
        <div className="LeftFixedMenu">
          <div className="row">
            <div className="col-12">
              <Navebar />
            </div>
          </div>
        </div>
      </div>
      <div className="rightSideBar2"></div>
      <div className="rightSideBar ticketedit">
        <div className="row">
          <div className="col-12">
            <div className="row topImgTTL">
              <div className="col-12 d-flex align-items-center pageTTL">
                <img src="/images/Menu.png" height="20" alt="Menu" />
                <h3>Ticket Edit</h3>
              </div>

              <div className="row py-5">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Ticket ID</label>
                    <p>{data.ticket_id}</p>
                  </div>
                  <div className="form-group">
                    <label>Opening Date</label>
                    <p>{data.formatted_date}</p>
                  </div>
                  <div className="form-group">
                    <label>Closed Date</label>
                    <p>{data.closed_date ?? "Null"}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <p>{data.vendor?.company}</p>
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <p>{data.subject}</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary btn-theme1 dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {data.status}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" onClick={resolveApi}>
                              {data.status == "resolved"
                                ? "Pending"
                                : "Resolved"}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-12">
                  <div className="form-group">
                    <label>Query</label>
                    <p>{data.query}</p>
                  </div>
                </div>
              </div>

              <Accordion defaultActiveKey="0" flush>
                {data &&
                  data.responds &&
                  data.responds.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div className="top">
                            <p>
                              <b>Date</b> {item.formatted_date}
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="bottom">
                            <p>
                              <b>
                                {item.role == "admin" ? "Support" : "Vendor"}
                              </b>{" "}
                              {item.message}
                            </p>
                            {item.attach && (
                              <p>
                                <b>Document</b>{" "}
                                <a href={item.url} target="_blank">
                                  Download
                                </a>
                              </p>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>

              <form onSubmit={respondApi}>
                <div className="form-group mb-4">
                  <label style={{ width: "20%" }}>Solution</label>
                  <textarea
                    required
                    ref={replyRef}
                    rows={5}
                    cols={6}
                    onInput={(e) => setMessage(e.target.value)}
                    placeholder="Type Here..."
                  />
                </div>
                <div className="form-group">
                  <label style={{ width: "20%" }}>Attach Document</label>
                  <input
                    type="file"
                    ref={attachRef}
                    onChange={handleFileChange}
                    name="doc"
                    accept="image/*, application/pdf"
                  />
                </div>

                <div className="buttons mt-4">
                  <button type="submit" disabled={enable} className="resolve">
                    Reply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketEdit;
