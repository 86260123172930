import React from 'react'

const CouponTable = ({data}) => {
  return (
    <>
            <div className='table mt-4 table-responsive loanTable couponTable'>
        <table className='table checkBoxTable '>
            <tr>
            <th></th>
                <th>Code</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Used</th>
                <th>Status</th>
                <th className='text-center'>Edit</th>
            </tr>   
            {data.map((CurntElem)=>{
                    return(
                        <>
                            <tr>
                                <td>
                                    <input type="checkbox" />
                                </td>
                                <td>{CurntElem.Code}</td>
                                <td>{CurntElem.Type}</td>
                                <td>{CurntElem.Amount}</td>
                                <td>{CurntElem.Used}</td>
                                <td className={CurntElem.Status}>{CurntElem.Status}</td>
                                <td><button type="button" className="btn btn-table" style={{margin:"0px auto",width:"80px",display:"block"}}>View</button></td>
                            </tr> 
                        </>
                    )
               })} 
        </table>    
    </div>
    
    </>
  )
}

export default CouponTable
