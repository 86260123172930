const RecentTransactionsData = [
    {
        id:1,
        TransactionID:1215353,
        User:"Shakti trader",
        TradeCompany:10000,
        Date:"20/8/2022",
        SpendAmount:10000
    },
    {
        id:2,
        TransactionID:1215353,
        User:"Shakti trader",
        TradeCompany:10000,
        Date:"20/8/2022",
        SpendAmount:10000
    },
    {
        id:3,
        TransactionID:1215353,
        User:"Shakti trader",
        TradeCompany:10000,
        Date:"20/8/2022",
        SpendAmount:10000
    },
    {
        id:4,
        TransactionID:1215353,
        User:"Shakti trader",
        TradeCompany:10000,
        Date:"20/8/2022",
        SpendAmount:10000
    },
    {
        id:5,
        TransactionID:1215353,
        User:"Shakti trader",
        TradeCompany:10000,
        Date:"20/8/2022",
        SpendAmount:10000
    }
]
export default RecentTransactionsData