import React from 'react'
import ChartData from './ChartApi'
import {ResponsiveContainer,CartesianGrid, BarChart, Bar, XAxis, Tooltip, YAxis} from 'recharts'
const Chart = () => {
  return (
    <>
        <div className='d-flex justify-content-bitween'>
            <div className='fSize18 fWeight6'>Revenue Overview</div>
            <div>
                <input type="radio" className="btn-check" name="options" id="option1" autocomplete="off" />
                <label style={{marginRight:"10px"}} className="btn btn-light" for="option1">Week</label>

                <input type="radio" className="btn-check" name="options" id="option2" autocomplete="off" />
                <label  style={{marginRight:"10px"}} className="btn btn-light" for="option2">Month</label>

                <input type="radio" className="btn-check" name="options" id="option4" autocomplete="off" />
                <label className="btn btn-light" for="option4">Year</label>
            </div>
        </div>
        <ResponsiveContainer width="100%" aspect={2.9}>
            <BarChart data={ChartData}>
            <XAxis  axisLine={false} dataKey="month"  />
            {/* interval="preserveStartEnd" */}
            <YAxis dataKey="sale"  axisLine={false} />
            <CartesianGrid stroke="#eee" strokeDasharray="4 4"/>
                <Bar type="monotone" dataKey="sale"  stroke="#042D5F" fill='#042D5F' strokeWidth={3}  barSize={30} radius={[3, 3, 0, 0]}></Bar>  
                <Bar type="monotone" dataKey="saleNew"  stroke="#A2D2FF" fill='#A2D2FF' strokeWidth={3}  barSize={30} radius={[3, 3, 0, 0]}></Bar>  
                <Tooltip  cursor={{fill: 'transparent'}} wrapperStyle={{ outline: "none" }} />          
            </BarChart>
        </ResponsiveContainer> 
        <hr style={{width:"95%",margin:"0px auto",marginBottom:"20px"}} />

        <div className='row' style={{width:"95%",margin:"0px auto",marginBottom:"20px"}}>
        <div className='col-md-4'>
                <div className='treeCard'>
                    <h4 className='greenClr'>Total Earning <img src='../images/green.png' alt="reen" /></h4>
                    <h3>Rs 24,000,000</h3>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='treeCard'>
                    <h4 className='greenClr'>Total Merchant’s </h4>
                    <h3>5225</h3>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='treeCard noBorder'>
                    <h4 className='redClr'>Total Expenditure  <img src='../images/red.png' alt="red" /></h4>
                    <h3>Rs 5,00,00</h3>
                </div>
            </div>
            
        </div>
    </>
  )
}

export default Chart
