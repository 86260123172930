import React, { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";

const CategoryTable = ({ reload, data }) => {
  const token = localStorage.getItem("token");

  const [show, setShow] = useState(false);

  //show api
  const [slug, setSlug] = useState("");
  const [parent, setParent] = useState("");
  const [userid, setUserId] = useState(null);

  // const { id } = useParams();

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };
  /*============================== 
                list api method 
      ================================*/

  useEffect(() => {
    reload();
  }, []);

  /*============================
            delete api method 
    =============================*/
  async function deletecategory(id) {
    const toastID = toast.loading("Processing...");

    let result = await fetch(`${baseUrl}/admin/category/${id}/destroy`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await result.json();

    if (data.error) {
      toast.update(toastID, {
        render: data.message,
        type: "error",
        autoClose: 1500,
        isLoading: false,
      });
      return;
    }

    toast.update(toastID, {
      render: data.message,
      type: "success",
      autoClose: 1500,
      isLoading: false,
    });

    //console.log('data updated', data)
    reload(); // to refresh page call list function
  }

  /*============================
        show(prefill data) api method 
    =============================*/
  const showcategory = async (id) => {
    const toastID = toast.loading("Fetching...");
    const result = await fetch(`${baseUrl}/admin/category/${id}/show`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await result.json();

    if (data.error) {
      toast.update(toastID, {
        render: data.message,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      return;
    }
    toast.dismiss(toastID);

    openModal();

    setSlug(data.data.name);
    setParent(data.data.parent);
    setUserId(data.data.id);
  };

  /*============================
         edit api method 
     =============================*/
  const editcategory = async (id, e) => {
    e.preventDefault();
    // console.log('id', id);
    let item = { slug, parent };
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/admin/category/${id}/edit`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();

    if (data.error) {
      toast.update(toastID, {
        render: data.message,
        type: "error",
        autoClose: 1500,
        isLoading: false,
      });
      return;
    }

    reload();

    closeModal();
    toast.update(toastID, {
      render: data.message,
      type: "success",
      autoClose: 1500,
      isLoading: false,
    });
  };

  return (
    <>
      <div className="table mt-4 table-responsive">
        <table className="table table-responsive ">
          <thead>
            <tr>
              <th></th>
              <th className="text-center">Category ID</th>
              <th className="text-center">Category Name</th>
              <th className="text-center">Parent Category</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.map((val, index) => {
                const { id, name, parent } = val;
                return (
                  <tr className="CatelougeTable" key={index}>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td className="text-center">{id}</td>
                    <td className="text-center text-capitalize">{name}</td>
                    <td className="text-center text-capitalize">{parent}</td>
                    <td className="text-center">
                      <span onClick={() => showcategory(id)}>
                        <i
                          className="fa-solid fa-pencil bg-primary me-2 p-2 rounded-circle text-white"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </span>
                      <span onClick={() => deletecategory(id)}>
                        <i
                          className="fa-solid fa-trash bg-danger me-2 p-2 rounded-circle text-white"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {show && (
        <div
          className="modal fade pdtCat show"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.45)" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <form onSubmit={(e) => editcategory(userid, e)}>
                <div className="modal-body">
                  <h4 className=" ms-0 pb-4"> Category Details</h4>
                  <div className="mb-3 form-group">
                    <label for="pass" className="form-label">
                      Parent Category
                    </label>
                    <select
                      aria-label="Default select example"
                      required
                      onChange={(e) => {
                        setParent(e.target.value);
                      }}
                    >
                      <option value={``}>Select Parent</option>
                      <option value={`api`} selected={parent === "api"}>
                        Api
                      </option>
                      <option
                        value={`chemicals`}
                        selected={parent === "chemicals"}
                      >
                        Chemicals
                      </option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Product Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      placeholder="Ex Adhesive"
                      onChange={(e) => setSlug(e.target.value)}
                      defaultValue={slug}
                    />
                  </div>
                </div>
                <div className="modal-footer" style={{ borderTop: "unset" }}>
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary btn-theme">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryTable;
