import React from 'react'
import MobileMenu from '../MobileMenu/MobileMenu'
import Navebar from '../navbar'

const AddCoupon = () => {
    return (
        <>
            <div className='Main-wrapper'>
                <MobileMenu />
                <div className='left_menu'>
                    <div className='LeftFixedMenu'>
                        <div className='row'>
                            <div className='col-12'>
                                <Navebar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rightSideBar2'></div>
                <div className='rightSideBar'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="row topImgTTL">
                                <div className="col-12 d-flex align-items-center pageTTL">
                                    <img src="../images/Menu.png" height="20" alt="Menu" />
                                    <h3>Coupons</h3>
                                </div>
                                <form className='mt-4'>
                                    <h2>Create Coupon</h2>
                                    <div className='row'>
                                        <div className='col-md-6 col-12'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">Rate Type</label>
                                                <input type="text" className="form-control" id="" placeholder="Ex FLAT50" />
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-12'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">Allow Product Type</label>

                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Ex Category or Subcategory</option>
                                                    <option value="1">Category 1</option>
                                                    <option value="2">Category 2</option>
                                                    <option value="3">Category 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-12'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">Category</label>

                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Ex Category or Subcategory</option>
                                                    <option value="1">Category 1</option>
                                                    <option value="2">Category 2</option>
                                                    <option value="3">Category 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-12'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">Type</label>

                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Ex Category or Subcategory</option>
                                                    <option value="1">Category 1</option>
                                                    <option value="2">Category 2</option>
                                                    <option value="3">Category 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">Type</label>
                                                <input type="text" className="form-control" id="" placeholder="Ex Adhesive or Chemical" />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">Start Date</label>
                                                <input type="date" className="form-control" id="" placeholder="Ex Adhesive or Chemical" />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="mb-4">
                                                <label for="exampleFormControlInput1" className="form-label">End Date</label>
                                                <input type="date" className="form-control" id="" placeholder="Ex Adhesive or Chemical" />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <button type="button" className="btn btn-primary btn-theme  float-end">Sacntioned Money</button>

                                            <button style={{ marginRight: "10px" }} type="button" className="btn btn-outline-theme float-end" data-bs-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCoupon
