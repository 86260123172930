import React from 'react'
import './style.css'
import Navebar from '../navbar'
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import {useEffect} from "react";

const MobileMenu = () => {
  useEffect ((useEffect)=>{
$(document).ready(function(){
  $('.Menu_icon').click(function(){
    $('.hideLeftMobileMenu').addClass('showLeftMobileMenu');
    $('.rightSideBar2').show()
    
  });
  $('.close_icon').click(function(){
    $('.hideLeftMobileMenu').removeClass('showLeftMobileMenu');
    $('.rightSideBar2').hide()
  });
  $('.rightSideBar2').click(function(){
    $('.hideLeftMobileMenu').removeClass('showLeftMobileMenu');
    $('.rightSideBar2').hide()  
  });
  
  $("ul.menuList > li").click(function(){
    $('.hideLeftMobileMenu').removeClass('showLeftMobileMenu');
    $('.rightSideBar2').hide()  
  })
})
  },[])
  
  return (
    <>
      <div className='mobile-header'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <img className='Menu_icon' src='/images/menuIcon.png' alt="menuIcon" />
            <img src='/images/logo.png' alt="menuIcon" style={{height:"20px"}} />
            
          </div>
          {/* <div>
            <img style={{marginRight:"10px",cursor:"pointer"}} src='../images/notification.png' alt="notification" />
          </div> */}
        </div>
        <div className='leftMobileMenu hideLeftMobileMenu'>
          <div className='mobileLogoClose'>
            <div className='d-flex justify-content-between align-items-center'>
              <img src='/images/logo.png'  style={{height:"20px",marginLeft:"16px"}} alt="logo" />
              <img className='close_icon' src='/images/close-icon.png' alt="logo" />

            </div>
          </div>
          <Navebar />
        </div>
      </div>
    </>
  )
}

export default MobileMenu
