import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import Pagination from "../component/Pagination";

const Trash = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const token = localStorage.getItem("token");

  const [search, setSearch] = useState("");
  const [ids, setIds] = useState([]);

  const searchRef = useRef(null);

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(
        `${baseUrl}/admin/trashed?page=${currentPage}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      setData(result.data.data);
      setLastPage(result.data.last_page);
      toast.dismiss(toastID);
    } catch (error) {
      toasterUpdate(toastID, error, "success");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  const restore = async (id) => {
    try {
      const toastID = toast.loading("Restoring...");
      const result = await fetch(`${baseUrl}/admin/${id}/restore`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await result.json();
      if (data.error) {
        toasterUpdate(toastID, data.message, "error");
        return;
      }

      toasterUpdate(toastID, data.message, "success");
      fetchApi();
      setIds([]);
    } catch (error) {
      toast.error(error);
    }
  };

  const searchGo = () => {
    setSearch(searchRef.current.value);
    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row topImgTTL">
            <div className="col-12 d-flex align-items-center pageTTL">
              <img src="/images/Menu.png" height="20" alt="Menu" />
              <h3>Trashed users</h3>
            </div>
            <div className="input-group my-4">
              <input
                type="text"
                ref={searchRef}
                className="form-control"
                placeholder=""
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <span
                onClick={searchGo}
                className="input-group-text searchBtn"
                id="basic-addon2"
              >
                Search
              </span>
            </div>
          </div>
          <div className="table table-responsive mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th>Role</th>
                  <th>KYC</th>
                  <th>Deleted Date</th>
                  <th className="text-end"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.vendor ? item.vendor.company : "null"}</td>
                      <td>{item.role}</td>
                      <td className="text-start">
                        {item.is_complete ? (
                          <i className="fas fa-check bg-success text-white px-3 py-2 rounded-3"></i>
                        ) : (
                          <i className="fas fa-times bg-danger text-white px-3 py-2 rounded-3"></i>
                        )}
                      </td>
                      <td>{item.deleted_at}</td>
                      <td className="text-center">
                        <button
                          onClick={() => restore(item.id)}
                          className="btn bg-danger text-white"
                        >
                          Restore
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            
          </div>
          <Pagination
              handlePageChange={handlePageChange}
              lastPage={lastPage}
              currentPage={currentPage}
            />
        </div>
      </div>
    </>
  );
};

export default Trash;
