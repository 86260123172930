import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const AwaitingResponseTable = ({ data, from }) => {
  return (
    <>
      <div className="table mt-4 table-responsive">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th className="text-nowrap">Sr. No.</th>
              <th className="text-nowrap">RFQ Name</th>
              <th>Merchant </th>
              <th className="text-nowrap">Date Created</th>
              <th className="text-nowrap">Order Type</th>
              <th className="text-end">Details</th>
            </tr>
          </thead>
          {data.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  {/* <td><input type="checkbox" /></td> */}
                  <td>{from + index}</td>
                  <td className="text-nowrap text-capitalize">{item.label}</td>
                  <td className="text-nowrap text-capitalize">{item.vendor ? item.vendor.vendor.company : 'null'}</td>
                  <td className="text-nowrap"><Moment format="ll - hh:mm a">{item.created_at}</Moment></td>
                  <td className="text-nowrap text-capitalize">{item.payment_type}</td>
                  <td>
                    <Link
                      to={`${item.id}/detail`}
                      className="btn btn-table float-end"
                    >
                      {" "}
                      View
                    </Link>
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default AwaitingResponseTable;
