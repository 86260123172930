import React from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const CatelougeTable = ({ data, action }) => {
  const { id, setId } = action;

  const storeID = (g_id) => {
    if (id.includes(g_id)) {
      setId(id.filter((i) => i !== g_id));
    } else {
      setId([...id, g_id]);
    }
  };

  return (
    <>
      <div className="table mt-4 table-responsive">
        <table className="table table-responsive ">
          <tr>
            <th></th>
            <th>ID</th>
            <th>HSN</th>
            <th>Cas No</th>
            <th>Product</th>
            <th>Form factor</th>
            <th>Category</th>
            <th>Created at</th>
            <th className="text-end">Action</th>
          </tr>
          {data.map((item,index) => {
            return (
                <tr className="CatelougeTable" key={index}>
                  <td>
                    <input
                      type="checkbox"
                      value={item.id}
                      onChange={() => storeID(item.id)}
                    />
                  </td>
                  <td>#{item.id}</td>
                  <td>{item.hsn}</td>
                  <td>{item.cas_no}</td>
                  <td className="text-capitalize">{item.name}</td>
                  <td className="text-capitalize">{item.state}</td>
                  <td className="text-capitalize">{item.category?.name || "null"}</td>
                  <td><Moment format="ll">{item.created_at}</Moment></td>

                  <td>
                    <Link
                      to={`${item.id}/detail`}
                      className="btn btn-table float-end"
                    >
                      View
                    </Link>
                  </td>
                </tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default CatelougeTable;
