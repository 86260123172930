import React from 'react'
import { NavLink } from 'react-router-dom'

const AllMenuItems = ({request}) => {
  return (
    <>
      {request.map((CurntElm, index)=>{
        return(
                <li key={index}>
                    <NavLink to={CurntElm.visitLink}>
                        <div className='d-flex align-items-center'>
                            <div className=''>
                                <img src={CurntElm.img} alt={CurntElm.name} className='nav_item_img' />
                            </div>
                            <div className=''>
                                {CurntElm.name}
                            </div>
                        </div> 
                    </NavLink>   
                </li>
        )
      })}
    </>
  )
}

export default AllMenuItems
