import React, { useEffect } from "react";
import { useState } from "react";
import AwatingResponseData from "./AwaitingResponseApi";
import AwaitingResponseTable from "./AwaitingResponseTable";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import Pagination from "../component/Pagination"


const Awaiting_Responses = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [from, setFrom] = useState(1);


  const [search, setSearch] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);


  const fetchApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/admin/rfqs?page=${currentPage}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    setFrom(result.data.from);
    toast.dismiss(toastID);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);

    const filtered = data.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase()) ||
      item.vendor?.vendor?.company.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();

  }, [currentPage]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Awaiting Responses</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by RFQ name, vendor name"
                    onInput={handleSearch}
                  />
                  <span className="input-group-text searchBtn" id="basic-addon2">
                    Search
                  </span>
                </div>

                {/*<div className="d-flex justify-content-between">
                  <div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary btn-theme1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            Edit
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-primary btn-theme1" type="button">
                      {" "}
                      Create RFQ +{" "}
                    </button>
                  </div>
  </div>*/}
                {/* <OrderTable data={data} /> */}
                {search.length === 0 ? (<AwaitingResponseTable data={data} from={from} />) : (<AwaitingResponseTable data={filteredOrders} from={from} />)}

                <Pagination handlePageChange={handlePageChange} lastPage={lastPage} currentPage={currentPage} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Awaiting_Responses;
