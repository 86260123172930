import React from 'react'

const TotalPurchaseTable = ({data, label}) => {
    
  return (
   
    <>    
    <div className='row'>
        <div className='col-6'>
            <h2>Total {label}</h2>
        </div>
        {/* <div className='col-6'>
            <select style={{width:"160px"}} className="form-select float-end" aria-label="Default select example">
                <option>Select month</option>
                <option>This month</option>
                <option>Last month</option>
                <option>Last 3 month</option>
                <option>Last 6 month</option>
                <option>This Year</option>
                <option>Last Year</option>
            </select>
        </div> */}
    </div>
    <div className='table mt-4  TotalPurchaseTable table-borderless table-responsive'>
        <table className='table'>
            <tr>
                <th>Order ID</th>
                <th>Merchant </th>
                <th>Date Created</th>
                <th>Quantity</th>
                <th>Order Type</th>
                <th className='text-center'>Details</th>
            </tr>
            {data.map((CurntElem)=>{
                return(
                    <>
                        <tr>
                            <td>{CurntElem.OrderID}</td>
                            <td>{CurntElem.Merchant }</td>
                            <td>{CurntElem.DateCreated}</td>
                            <td>{CurntElem.Quantity}</td>
                            <td>{CurntElem.OrderType}</td>
                            <td><button type="button" className="btn btn-table" style={{margin: "0px auto", width: "80px", display: "block"}}>View</button></td>
                        </tr>
                    </>
                )
            })}
        </table>
    </div>
    </>
  )
}

export default TotalPurchaseTable
