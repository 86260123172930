import React from "react";
import { Link } from "react-router-dom";

const LoanTable = ({ data }) => {
  return (
    <>
      <div className="table mt-4 table-responsive loanTable">
        <table className="table checkBoxTable ">
          <tr>
          <th>Sr No.</th>
            <th>Company</th>
            <th>Sanctioned Limit</th>
            <th>Balance</th>
            <th className="text-center">Details</th>
          </tr>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.vendor?.company}</td>
                <td>{item.sanctioned_amount}</td>
                <td>{item.balance}</td>
                <td>
                  <Link
                    to={`${item.vendor?.user_id}/detail`}
                    className="btn btn-table"
                    style={{
                      margin: "0px auto",
                      width: "80px",
                      display: "block",
                    }}
                  >
                    View
                  </Link>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default LoanTable;
