import React, { useState } from 'react'
import LogTable from './LogTable'
import LogData from './LogDataApi'
import Navebar from '../navbar';
import MobileMenu from '../MobileMenu/MobileMenu';


const AwaitingResponseView = () => {
  const [Data, setData] = useState(LogData);
  return (
    <>
      <div className='Main-wrapper'>
        <MobileMenu />
        <div className='left_menu'>
          <div className='LeftFixedMenu'>
            <div className='row'>
              <div className='col-12'>
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className='rightSideBar2'></div>
        <div className='rightSideBar'>
          <div className='row'>
            <div className='col-12'>
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>RFQ Details</h3>
                </div>
                <div className="input-group my-4">
                  <input type="text" className="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                  <span className="input-group-text searchBtn" id="basic-addon2">Search</span>
                </div>
              </div>

              <div className='row RFQDetails'>
                <h3>RFQ Details</h3>
                <div className='col-md-4'>
                  <h4>SA2502</h4>
                  <p>RFQ Name</p>
                </div>
                <div className='col-md-4'>
                  <h4>20/9/2022</h4>
                  <p>RFQ Till Date</p>
                </div>
                <div className='col-md-4'>
                  <h4>Adani Ltd.</h4>
                  <p>Buyer’s Name</p>
                </div>
              </div>
              <hr />

              <h2 style={{ fontSize: "23px", fontWeight: "600", marginTop: "30px" }}>Requested Items</h2>
              <div className='row'>
                <div className='col-md-8 col-12'>
                  <div style={{ background: "#F8F9FB", boxSizing: "border-box", padding: "0px 10px", marginBottom: "10px", height: "40px", lineHeight: "40px" }}>
                    <div className='d-flex justify-content-between'>
                      <p>Products</p>
                      <p>Quantity</p>
                    </div>
                  </div>
                  <div style={{ background: "#F8F9FB", boxSizing: "border-box", padding: "0px 10px", marginBottom: "10px", lineHeight: "40px" }}>
                    <div className='d-flex justify-content-between'>
                      <p className='mb-0'>Camlin Engineers Blue Pastes 20 ml</p>
                      <p className='mb-0 lightGraytextColor'>1500 pcs</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p className='mb-0 lightGraytextColor'>Adhesives</p>
                      <p className='mb-0 lightGraytextColor'>₹27.00 per piece</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-12'>
                  <div style={{ background: "#F8F9FB", boxSizing: "border-box", padding: "0px 10px", marginBottom: "10px", height: "40px", lineHeight: "40px" }}>
                    <div>
                      <p>RFQ Product Price Type</p>
                    </div>
                  </div>
                  <div style={{ background: "#F8F9FB", boxSizing: "border-box", padding: "0px 10px", marginBottom: "10px", lineHeight: "40px" }}>
                    <div>
                      <p className='mb-0'>Landing Price</p>
                    </div>
                    <div className='d-flex'>
                      <a href="">Edit</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row odrVw mt-4'>
                <div className='col-md-4 col-12'>
                  <h3>Delivery Address</h3>
                  <p>Karve Road , kothiyawala , Pune , India 500212 </p>
                </div>
                <div className='col-md-4 col-12'>
                  <h3>Requested Delivery Pcs</h3>
                  <p>10 </p>
                </div>
                <div className='col-md-4 col-12'>
                  <h3>Expected Delivery Date</h3>
                  <p>20/10/2022</p>
                </div>
              </div>
              <div className='logsSec'>
                <h3>Logs </h3>
                <LogTable Data={Data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AwaitingResponseView
