const MenuItems = [
  {
    id: 1,
    name: "dashboard",
    img: "/images/menuIcons/dashboard.png",
    visitLink: "/",
  },
  {
    id: 2,
    name: "Orders",
    img: "/images/menuIcons/Bag.png",
    visitLink: "/orders",
  },
  {
    id: 3,
    name: "Awaiting Responses",
    img: "/images/menuIcons/File_dock.png",
    visitLink: "/awaiting-responses",
  },
  // {
  //   id: 4,
  //   name: "Payments",
  //   img: "/images/menuIcons/Money.png",
  //   visitLink: "/payments",
  // },
  {
    id: 5,
    name: "Sanctioned Money",
    img: "/images/menuIcons/Wallet_alt.png",
    visitLink: "/loan",
  },
  {
    id: 5,
    name: "Sanction Requests",
    img: "/images/menuIcons/Wallet_alt.png",
    visitLink: "/requests",
  },
  {
    id: 6,
    name: "Merchants",
    img: "/images/menuIcons/User_alt.png",
    visitLink: "/merchants",
  },
  {
    id: 7,
    name: "Supports",
    img: "/images/menuIcons/Ticket.png",
    visitLink: "/support",
  },
  {
    id: 8,
    name: "User",
    img: "/images/menuIcons/User_add.png",
    visitLink: "/roles",
  },
  {
    id: 9,
    name: "Coupons",
    img: "/images/menuIcons/Ticket_alt.png",
    visitLink: "/coupons",
  },
  // {
  //   id: 10,
  //   name: "Comissions",
  //   img: "/images/menuIcons/pie_chart.png",
  //   visitLink: "/comissions",
  // },
  {
    id: 11,
    name: "Subcribe Email",
    img: "/images/menuIcons/Message.png",
    visitLink: "/subscribers",
  },
  {
    id: 12,
    name: "Product Catalouge",
    img: "/images/menuIcons/Book_check.png",
    visitLink: "/catalogs",
  },
  {
    id: 12,
    name: "Product Category",
    img: "/images/menuIcons/Book_check.png",
    visitLink: "/categories",
  },
  {
    id: 13,
    name: "Trash",
    img: "/images/menuIcons/trash-icon.png",
    visitLink: "/trash",
  },
  {
    id: 14,
    name: "Queries",
    img: "/images/menuIcons/Desk.png",
    visitLink: "/contacts",
  },
  {
    id: 15,
    name: "Reports",
    img: "/images/menuIcons/Desk.png",
    visitLink: "/reports",
  },
];
export default MenuItems;
