import React, { useEffect, useState } from "react";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import { toast } from "react-toastify";

const EditRole = () => {
  const token = localStorage.getItem("token");

  const { id } = useParams();

  const [name, SetName] = useState("");
  const [phone, SetPhone] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [role, SetRole] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  async function editApi(event) {
    event.preventDefault();

    setIsLoading(true);
    let item = { name, phone, email, role };

    if (password.length > 0) {
      item = { ...item, password };
    }

    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/admin/${id}/edit`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();

    setIsLoading(false);
    if (data.error) {
      toasterUpdate(toastID, data.message, "error");
      return;
    }
    toasterUpdate(toastID, data.message, "success");
    navigate("/roles");
  }

  const fetchApi = async () => {
    const toastID = toast.loading("Processing...");
    setIsLoading(true);

    const response = await fetch(`${baseUrl}/admin/${id}/show`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    if (data.error) {
      setIsLoading(false);

      toasterUpdate(toastID, data.message, "error");
      return;
    }
    SetName(data.data.name);
    SetPhone(data.data.phone);
    SetEmail(data.data.email);
    SetRole(data.data.role);


    setIsLoading(false);
    toast.dismiss(toastID);
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>User</h3>
                </div>
                <form onSubmit={editApi} className="mt-4 createuser">
                  <h2 className="mt-3 fSize20 fWeight6">Edit User</h2>

                  <div className="flex-box">
                    <div className="mb-3 form-group">
                      <label for="Name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Name"
                        placeholder="Enter Name"
                        defaultValue={name}
                        onInput={(e) => SetName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 form-group">
                      <label for="Phone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Phone"
                        placeholder="Enter Phone"
                        defaultValue={phone}
                        onInput={(e) => SetPhone(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Email ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Enter Email"
                      defaultValue={email}
                      onInput={(e) => SetEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="flex-box">
                    <div className="mb-3 form-group">
                      <label for="pass" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="pass"
                        placeholder="Enter Password"
                        defaultValue={password}
                        onChange={(e) => SetPassword(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 form-group">
                      <label for="pass" className="form-label">
                        Role
                      </label>
                      <select
                        aria-label="Default select example"
                        onChange={(e) => SetRole(e.target.value)}
                      >
                        <option value={""}>select</option>
                        <option
                          value="super admin"
                          selected={role === "super admin"}
                        >
                          super admin
                        </option>
                        <option value="admin" selected={role === "admin"}>
                          admin
                        </option>
                        <option value="vendor" selected={role === "vendor"}>
                          vendor
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-10 mt-5">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn float-end btn-primary btn-theme"
                    >
                      Save{" "}
                    </button>
                  </div>
                </form>
                {/* <h2 className='mt-5 fSize18 fWeight6'>Permissions</h2> */}

                {/* <div className='row'>
                                            <div className='col-md-4 col-6  mt-4'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" for="Address1">Address 1</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="Address1" />

                                                </div>
                                            </div>
                                            <div className='col-md-4 col-6  mt-4'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" for="Address2">Address 2</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="Address2" />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-6  mt-4'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" for="Address3">Address 3</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="Address3" />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-6  mt-4'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" for="Address4">Address 4</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="Address4" />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-6  mt-4'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" for="Address5">Address 5</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="Address5" />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-6  mt-4'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" for="Address6">Address 6</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="Address6" />
                                                </div>
                                            </div>
                                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
