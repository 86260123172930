import React, { useEffect, useRef, useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import { useNavigate } from "react-router-dom";

const AddProductCatalouge = () => {
  const [slug, setslug] = useState("");
  const [hsn, sethsn] = useState("");
  const [cas_no, setcas_no] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [description, setdescription] = useState("");
  const [state, setstate] = useState("");
  const [base_image, setbase_image] = useState("");
  const [additional_files, setadditional_files] = useState([]);
  const [documents, setdocuments] = useState([]);

  const [categories, setcategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const baseRef = useRef(null);
  const additionalRef = useRef(null);
  const documentRef = useRef(null);

  const navigate = useNavigate();

  const handleDocumentsChange = (event) => {
    setdocuments(Array.from(event.target.files));
  };

  const handleAdditionalFilesChange = (event) => {
    setadditional_files(Array.from(event.target.files));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "image/webp") {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 200000) {
          setbase_image("");
          baseRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 200kb."
          );
        } else {
          setbase_image(file);
        }
      };
    } else {
      setbase_image("");
      baseRef.current.value = null;
      toast.error("Please select a valid WebP image format.");
    }
  };

  const createApi = async () => {
    const token = localStorage.getItem("token");

    const toastID = toast.loading("Processing...");

    setIsLoading(true);

    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("description", description);
    formData.append("category_id", category_id);
    formData.append("hsn", hsn);
    formData.append("cas_no", cas_no);
    formData.append("base_image", base_image);
    formData.append("state", state);

    additional_files.forEach((item) => {
      formData.append("additional_files[]", item);
    });

    documents.forEach((item) => {
      formData.append("documents[]", item);
    });

    const response = await fetch(`${baseUrl}/admin/catalogue`, {
      method: "post",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    const result = await response.json();

    setIsLoading(false);

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    navigate("/catalogs");
    toasterUpdate(toastID, result.message, "success");
  };

  const categoriesApi = async () => {
    const token = localStorage.getItem("token");

    const response = await fetch(`${baseUrl}/admin/main/categories`, {
      headers: {
        Authorization: token,
      },
    });

    const result = await response.json();

    if (result.error) {
      return;
    }
    setcategories(result.data);
  };

  useEffect(() => {
    categoriesApi();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="../images/Menu.png" height="20" alt="Menu" />
                  <h3>Product Catalogs</h3>
                </div>

                <div className="borderSetion mt-4">
                  <h2 className="fSize16">General Information</h2>
                  <div className="row mt-3">
                    <div className="col-md-12 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          defaultValue={slug}
                          onInput={(e) => {
                            setslug(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Hsn Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={hsn}
                          onInput={(e) => {
                            sethsn(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Cas No
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={cas_no}
                          onInput={(e) => {
                            setcas_no(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Category
                        </label>
                        <select
                          onChange={(e) => {
                            setcategory_id(e.target.value);
                          }}
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value={""}>Select Category</option>
                          {categories.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Form Factor
                        </label>
                        <select
                          onChange={(e) => {
                            setstate(e.target.value);
                          }}
                          required
                          className="form-select"
                          aria-label="Default select example"
                        >
                        <option value={``}>{`select`}</option>
                        <option value={`solid`}>{`Solid`}</option>
                        <option value={`liquid`}>{`Liquid`}</option>
                        <option value={`gas`}>{`Gas`}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Description
                        </label>
                        <textarea
                          defaultValue={description}
                          onInput={(e) => {
                            setdescription(e.target.value);
                          }}
                          className="form-control"
                          rows="4"
                          cols="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="borderSetion mt-4">
                  <h2 className="fSize16">Price Information</h2>
                  <div className="row mt-3">
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Product Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Product GST
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Logistics GST
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Expected Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Landed Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                        </div> */}

                {/*<div className="borderSetion mt-4">
                  <h2 className="fSize16">Inventory</h2>
                  <div className="row mt-3">
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          SKU
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Ex 50KD85"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Stock Availability
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Open this select menu</option>
                          <option value="1">Out of stock</option>
                          <option value="2">In stock</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Inventory Management
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Ex</option>
                          <option value="1">Ex</option>
                          <option value="2">Ex</option>
                        </select>
                      </div>
                    </div>
                  </div>
                        </div>*/}
                <div className="borderSetion mt-4">
                  <h2 className="fSize16">Product Images</h2>
                  <div className="row mt-3">
                    <div className="col-11">
                      <div className="mb-4">
                        <label for="formFile" className="form-label">
                          Primary image{" "}
                          <small className="text-danger">{`(accepts only webp format*)`}</small>
                        </label>
                        <input
                          ref={baseRef}
                          className="form-control"
                          type="file"
                          accept="image/webp"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <button
                        style={{ marginTop: "32px" }}
                        type="button"
                        className="btn btn-info"
                      >
                        <img src="/images/upload.png" alt="upload" />
                      </button>
                    </div>
                    <div className="col-11">
                      <div className="mb-3">
                        <label for="formFile" className="form-label">
                          Additional Images
                          <small className="text-danger">{` [accepts only webp format* | `}</small>
                          <small className="text-secondary">{`you can select multiple images]`}</small>
                        </label>
                        <input
                          ref={additionalRef}
                          className="form-control"
                          type="file"
                          accept="image/webp"
                          multiple={true}
                          onChange={handleAdditionalFilesChange}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <button
                        style={{ marginTop: "32px" }}
                        type="button"
                        className="btn btn-info"
                      >
                        <img src="/images/upload.png" alt="upload" />
                      </button>
                    </div>
                    <div className="col-11">
                      <div className="mb-3">
                        <label for="formFile" className="form-label">
                          Documents
                          <small className="text-danger">{` [accepts only pdf format* | `}</small>
                          <small className="text-secondary">{`you can select multiple documents]`}</small>
                        </label>
                        <input
                          ref={documentRef}
                          className="form-control"
                          type="file"
                          accept="application/pdf"
                          multiple={true}
                          onChange={handleDocumentsChange}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <button
                        style={{ marginTop: "32px" }}
                        type="button"
                        className="btn btn-info"
                      >
                        <img src="/images/upload.png" alt="upload" />
                      </button>
                    </div>
                  </div>
                </div>

                {/*<div className="borderSetion mt-4">
                  <h2 className="fSize16">SEO</h2>
                  <div className="row mt-3">
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Ex www.google.com/facebook"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Meta Title
                        </label>
                        <input type="text" className="form-control" id="" />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Meta Decription
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          cols="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                    </div>*/}
                {/*<div className="borderSetion mt-4">
                  <h2 className="fSize16">Add Attributes</h2>
                  <div className="row mt-3">
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          Attributes
                        </label>
                        <input type="text" className="form-control" id="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <label for="" className="form-label">
                          value
                        </label>
                        <input type="text" className="form-control" id="" />
                      </div>
                    </div>
                    <button
                      style={{
                        width: "120px",
                        marginLeft: "10px",
                        marginTop: "20px",
                      }}
                      type="button"
                      className="btn btn-primary btn-theme1"
                    >
                      Add More +
                    </button>
                  </div>
                    </div>*/}

                <div className="col-12 mt-5">
                  <button
                    style={{
                      width: "120px",
                      float: "right",
                      marginLeft: "10px",
                      marginTop: "20px",
                    }}
                    type="button"
                    className="btn btn-primary btn-theme1"
                    onClick={createApi}
                    disabled={isLoading}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProductCatalouge;
