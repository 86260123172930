import React from "react";
import { Link } from "react-router-dom";

const SupportTable = ({ data }) => {
  return (
    <>
      <div className="table table-responsive">
        <table className="table checkBoxTable ">
          <thead>
            <tr>
              <th>Ticket ID</th>
              <th>Company</th>
              <th>Subject</th>
              <th>Status</th>
              <th className="text-center">Details</th>
            </tr>
          </thead>
          {data.map((item) => {
            return (
              <>
                <tr>
                  <td>#{item.ticket_id}</td>
                  <td>{item.user?.vendor.company}</td>
                  <td>{item.subject}</td>
                  <td><button className={`btn btn-sm text-white ${item.status == "pending" ? 'bg-danger' : 'bg-success'}`}>{item.status}</button></td>
                  <td>
                    <Link
                      to={`${item.id}/details`}
                      className="btn btn-table"
                      style={{
                        margin: "0px auto",
                        width: "80px",
                        display: "block",
                      }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default SupportTable;
