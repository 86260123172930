import React, { useEffect, useRef } from "react";
import { useState } from "react";
import LoanData from "./LoanApi";
import LoanTable from "./LoanTable";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import Pagination from "../component/Pagination";

const Loan = () => {
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("token");

  const searchRef = useRef(null);

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(
        `${baseUrl}/admin/wallet?page=${currentPage}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      console.log(result);

      if (result.error) {
        toasterUpdate(toastID, data.message, "error");
        return;
      }

      setData(result.data);
      setRecords(result.data.records.data);
      setLastPage(result.data.records.last_page);
      toast.dismiss(toastID);
    } catch (error) {
      toasterUpdate(toastID, error, "success");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  const searchGo = () => {
    setSearch(searchRef.current.value);
  };

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, [search]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Sanctioned Money</h3>
                </div>
                {/* <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <span
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                  >
                    Search
                  </span>
                </div> */}

                <div className="my-4">
                  <div className="">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Vendors{" "}
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Seller’s</button>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    {/* <div className="dropdown">
                      <button className="btn btn-secondary btn-theme1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">Edit</a></li>
                        <li><a className="dropdown-item" href="#">Delete</a></li>

                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tab-content mt-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <LoanTable data={records} />

                    <Pagination
                      handlePageChange={handlePageChange}
                      lastPage={lastPage}
                      currentPage={currentPage}
                    />
                  </div>
                  {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                    <LoanTable data={data} />
                  </div> */}
                </div>
                {/* <OrderTable data={data} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loan;
