import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

const RoleTable = ({ action, api }) => {
  const { id, setId } = action;
  const [openedUser, setOpenedUser] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    id: "",
  });

  const storeID = (g_id) => {
    if (id.includes(g_id)) {
      setId(id.filter((i) => i !== g_id));
    } else {
      setId([...id, g_id]);
    }
  };

  const closeRef = useRef();

  return (
    <>
      <div className="table mt-4 table-responsive">
        <table className="table checkBoxTable ">
          <tr>
            <th></th>
            <th style={{ width: "170px" }}>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Role</th>
            <th className="text-center">Details</th>
          </tr>
          {api &&
            api.map((val, index) => {
              const { name, phone, email, role } = val;
              return (
                <React.Fragment>
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={id.includes(val.id)}
                        onChange={() => storeID(val.id)}
                      />
                    </td>
                    <td style={{ width: "170px" }}>{name}</td>
                    <td>{phone}</td>
                    <td>{email}</td>
                    <td>{role}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-table"
                        style={{
                          margin: "0px auto",
                          width: "80px",
                          display: "block",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => setOpenedUser(val)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
        </table>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="ms-0">User Details</h3>
              <div className="table responsive-table paymentPoupBx table-borderless">
                <table className="table">
                  <tr>
                    <td>Name</td>
                    <td>{openedUser.name}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{openedUser.role}</td>
                  </tr>
                  <tr>
                    <td>Email ID</td>
                    <td>{openedUser.email}</td>
                  </tr>
                  <tr>
                    <td>Phone No.</td>
                    <td>{openedUser.phone}</td>
                  </tr>
                  {/* <tr>
                        <td>Password</td>
                        <td>******* <Link>See</Link></td>
                </tr>*/}
                </table>
              </div>
            </div>
            <div className="modal-footer" style={{ borderTop: "unset" }}>
              <button
                type="button"
                className="btn btn-outline-theme"
                data-bs-dismiss="modal"
                ref={closeRef}
              >
                Cancel
              </button>
              <Link
                className="btn btn-outline-theme"
                to={`/roles/${openedUser.id}/edit`}
                onClick={() => closeRef.current.click()}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleTable;
