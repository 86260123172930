import React from "react";
import { Link } from "react-router-dom";

const MarchantListTable = ({ data }) => {
  return (
    <>
      <div className="table table-responsive table-responsive">
        <table className="table lastTableRowNoBOrder">
          <tr>
            <th>Name</th>
            <th>Company</th>
            <th>City</th>
            <th>Detail</th>
          </tr>

          {data.map((item) => {
            return (
              <>
                <tr>
                  <td>{item.name}</td>
                  <td>{item.vendor?.company || "---"}</td>
                  <td>{item.vendor?.city || "---"}</td>
                  <td>
                    <Link to={`/merchants/${item.id}/detail`}>Details</Link>
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default MarchantListTable;
