const LogData =[
    {
        id:1,
        RFQID:12132,
        CustomerName:"Adani Ltd",
        Status:"Revaluate",
        Price:12000
    },
    {
        id:2,
        RFQID:12132,
        CustomerName:"Adani Ltd",
        Status:"Revaluate",
        Price:12500
    },
    {
        id:3,
        RFQID:12132,
        CustomerName:"Adani Ltd",
        Status:"Revaluate",
        Price:13000
    },
    {
        id:4,
        RFQID:12132,
        CustomerName:"Adani Ltd",
        Status:"Revaluate",
        Price:10000
    },
    {
        id:5,
        RFQID:12132,
        CustomerName:"Adani Ltd",
        Status:"Revaluate",
        Price:14000
    },
    {
        id:6,
        RFQID:12132,
        CustomerName:"Adani Ltd",
        Status:"Revaluate",
        Price:11000
    },
]
export default LogData