import React, { useEffect, useRef, useState } from "react";
import RoleTable from "./RoleTable";
import { Link } from "react-router-dom";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import Pagination from "../component/Pagination"


const Roles = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const token = localStorage.getItem("token");

  const searchRef = useRef(null);
  const [id, setId] = useState([]);
  const searchGo = () => {
    setSearch(searchRef.current.value);
  };

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(
        `${baseUrl}/admin/roles?page=${currentPage}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      toast.dismiss(toastID);

      setData(result.data.data);
      setLastPage(result.data.last_page);
    } catch (error) {
      toasterUpdate(toastID, error, "success");
    }
  };

  const onDelete = async () => {
    try {
      const toastID = toast.loading("Deleting...");
      const result = await fetch(`${baseUrl}/admin/delete`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: id }),
      });
      const data = await result.json();
      if (data.error) {
        toasterUpdate(toastID, data.message, "error");
        return;
      }

      toasterUpdate(toastID, data.message, "success");
      fetchApi();
    } catch (error) {
      toast.error(error);
    }
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi()
  };


  useEffect(() => {
    fetchApi();
  }, [search]);

  return (
    <React.Fragment>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>User</h3>
                </div>
                <div className="input-group my-4">
                  <input
                    type="text"
                    ref={searchRef}
                    className="form-control"
                    placeholder="Search by name, email, phone"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <span
                    onClick={searchGo}
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                  >
                    Search
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary btn-theme1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <button className="dropdown-item" onClick={onDelete}>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <Link
                      to="create"
                      className="btn btn-primary btn-theme1"
                      type="button"
                    >
                      {" "}
                      Add Role +{" "}
                    </Link>
                  </div>
                </div>

                <RoleTable api={data} action={{ id, setId }} />
                <Pagination handlePageChange={handlePageChange} lastPage={lastPage}  currentPage={currentPage} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Roles;
