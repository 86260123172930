import React, {useEffect, useState } from "react";
import "./dashboard.css";

import SData from "./carddataApi";
import Card from "./card";
import Chart from "./Chart";
import Merchant_List from "./Merchant_List";
import Navebar from "../navbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";

const Dashboard = () => {
  // console.log(SData)
  const [cardData, setCardData] = useState(SData);

  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");

  const fetchUsers = async () => {
    try {
      const result = await fetch(`${baseUrl}/admin/dashboard/merchants`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await result.json();

      if (data.error) {
        toast.error(data.message);
        return;
      }

      setData(() => {
        return data.data;
      });
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12"></div>
            <div className="row topImgTTL">
              <div className="col-12 d-flex align-items-center pageTTL">
                <img src="/images/Menu.png" height={20} alt="Menu" />
                <h3>Dashboard</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-12">
                <Card data={cardData} />
              </div>
              <div className="col-md-9 col-12">
                <div className="chartSection">
                  <Chart />
                </div>
              </div>
            </div>
              <Merchant_List data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
