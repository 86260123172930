const SData =[
    {
        id:1,
        CirClr:"color1",
        name:"Tickets",
        num:15,
        status:"+ 5 from Last Month",
        upDown:"up",
        img:"../images/DashCardImg1.png",
    },
    {
        id:2,
        CirClr:"color2",
        name:"Recent Order",
        num:60,
        status:"+ 15 from Last Month",
        upDown:"up",
        img:"../images/DashCardImg1.png",
    },
    {
        id:3,
        CirClr:"color3",
        name:"Listed Merchants",
        num:5225,
        status:"+ 15 from Last Month",
        upDown:"up",
        img:"../images/DashCardImg1.png",
    },
    {
        id:4,
        CirClr:"color4",
        name:"Suspended Merchants",
        num:520,
        status:"+ 10 from Last Month",
        upDown:"down",
        img:"../images/DashCardImg1.png",
    },

]
export default SData