import React, { useRef } from 'react'
import { useState } from 'react'
import ReportsData from './ReportsApi'
import ReportsTable from './ReportsTable'
import MobileMenu from '../MobileMenu/MobileMenu'
import Navebar from '../navbar'

const Reports = () => {
  const [date, setDate] = useState(null);
  const dateRef = useRef(null);

  return (
    <>
      <div className='Main-wrapper'>
        <MobileMenu />
        <div className='left_menu'>
          <div className='LeftFixedMenu'>
            <div className='row'>
              <div className='col-12'>
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className='rightSideBar2'></div>
        <div className='rightSideBar'>
          <div className='row'>
            <div className='col-12'>
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="../images/Menu.png" height="20" alt="Menu" />
                  <h3>Reports</h3>
                </div>
                <div className="form-group my-4">
                  <label className='form-label' onClick={() => dateRef.current.click()}>Report Year/Month * <small className='text-danger'>{"("}Month & Year selection is mandatory{")"}</small></label>
                  <input ref={dateRef} type="month" onChange={(e) => setDate(e.target.value)} className="form-control" />
                </div>

                <ReportsTable date={date} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reports
