import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const OrderTable = ({ data }) => {
  return (
    <>
      <div className="table mt-4 table-responsive">
        <table className="table table-responsive ">
          <tr>
            <th>Products</th>
            <th className="text-nowrap">Merchant</th>
            <th className="text-nowrap">Payment Mode</th>
            <th className="text-nowrap">Ordered Date</th>
            <th>Status</th>
            <th className="text-end">Action</th>
          </tr>
          {data.map((item, index) => {
            return (
              <>
                <tr className="SKUIDROW" key={index}>
                  <td className="d-flex mb-0 pb-0">
                    <input type="checkbox" />
                    <img
                      style={{width: '150px',height:'100px' }}
                      src={item.product.url}
                      alt={item.product.name}
                    />
                    <div>
                      <p className="ProductName text-nowrap text-capitalize">{item.product.name}</p>

                      <table className="table SKUIDTable">
                        <tr>
                          <th>SKU ID </th>
                          <td className="text-nowrap">{item.product.sku}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">{item.order_id}</th>
                        </tr>
                      </table>
                    </div>
                  </td>
                  <td className="fWeight5 text-nowrap theme-color">{item.seller?.vendor.company}</td>
                  <td className="fWeight5 text-nowrap">{item.payment_mode}</td>
                  <td className="fWeight5 text-nowrap"><Moment format="ll">{item.created_at}</Moment></td>
                  <td className="fWeight5 text-nowrap">{item.order_status}</td>
                  <td>
                    <Link
                      to={`${item.id}/detail`}
                      className="btn btn-table float-end"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default OrderTable;
