import React, { useState } from 'react'
import {baseUrl} from "../utils/baseUrl";

const Register = () => {

  const [name, SetName] = useState("");
  const [phone, SetPhone] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");

  async function signup(event) {
    event.preventDefault();
    let item = { name, phone, email, password }

    try {
      const response = await fetch(`${baseUrl}/admin/register`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(item)
      });
      const data = await response.json();
      console.log('Data', data); // Do something with the response data
    } catch (error) {
      console.log(error); // Handle error appropriately
    }

    // const result = await fetch("", {}
    // console.log(item)
  }



  return (
    <>
      <div className="register-page py-5 px-5">
        <form onSubmit={signup}>
          <div className="mb-4">
            <label for="exampleFormControlInput1" className="form-label">Name</label>
            <input type="text" className="form-control" placeholder="Name"
              value={name} onChange={(e) => SetName(e.target.value)} />
          </div>
          <div className="mb-4">
            <label for="exampleFormControlInput1" className="form-label">Phone</label>
            <input type="text" className="form-control" placeholder="Phone"
              value={phone} onChange={(e) => SetPhone(e.target.value)} />
          </div>
          <div className="mb-4">
            <label for="exampleFormControlInput1" className="form-label">Email</label>
            <input type="email" className="form-control" placeholder="Email" value={email}
              onChange={(e) => SetEmail(e.target.value)} />
          </div>
          <div className="mb-4">
            <label for="exampleFormControlInput1" className="form-label">Password</label>
            <input type="password" className="form-control" id="" placeholder="Password" value={password}
              onChange={(e) => SetPassword(e.target.value)} />
          </div>
          <button type='submit' className='btn btn-primary'>Send</button>
        </form>
      </div>
    </>
  )
}

export default Register