const ChartData =[
    {
        id:1,
        month:"Jan",
        sale:10,
        saleNew:10,

    },
    {
        id:2,
        month:"Feb",
        sale:5,
        saleNew:15,

    },
    {
        id:3,
        month:"Mar",
        sale:30,
        saleNew:10,

    },
    {
        id:4,
        month:"Apr",
        sale:10,
        saleNew:30,

    },
    {
        id:5,
        month:"May",
        sale:50,
        saleNew:70,

    },
    {
        id:6,
        month:"June",
        sale:20,
        saleNew:35,

    },
    {
        id:7,
        month:"July",
        sale:10,
        saleNew:25,

    },
]
export default ChartData