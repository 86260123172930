import "./App.css";

import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Dashboard from "./dashboard";
import Orders from "./orders";
import Awaiting_Responses from "./awaiting-responses";
import Payments from "./payments";
import Loan from "./loan";
import Merchents from "./merchents";
import Supports from "./supports";
import Roles from "./roles";
import Coupons from "./coupons";
import Comissions from "./comissions";
import Subcribe_Email from "./subcribe-email";
import Product_Catalouge from "./product-catalouge";
import Reports from "./reports";
import AwaitingResponseView from "./awaiting-responses/AwaitingResponseView";
import LoanDetail from "./loan/LoanDetail";
import AddCoupon from "./coupons/AddCoupon";
import CreateRole from "./roles/CreateRole";
import CatelougeDetailView from "./product-catalouge/CatelougeDetailView";
import OrderDetail from "./orders/OrderDetail";
// import MobileMenu from './MobileMenu/MobileMenu';
import Login from "./Access/Login";
import Register from "./Access/Register";
import AddProductCatalouge from "./product-catalouge/AddProductCatalouge";
import PdtCategory from "./product-category/PdtCategory";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditProductCatalouge from "./product-catalouge/EditProductCatalouge";
import EditRole from "./roles/EditRole";
import CompanyDetail from "./customer/CompanyDetail";
import Trash from "./Trash";
import TicketEdit from "./supports/TicketEdit";
import Create from "./merchents/Create";
import Edit from "./merchents/Edit";
import RequestList from "./loan/RequestList";
import Contact from "./contact";
import BulkUpload from "./product-catalouge/BulkUpload";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="App">
          <ToastContainer autoClose={1500} />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/trash" element={<Trash />} />

            <Route path="/orders">
              <Route index element={<Orders />} />
              <Route path="detail" element={<OrderDetail />} />
              <Route path=":id/detail" element={<OrderDetail />} />
            </Route>

            <Route path="/awaiting-responses">
              <Route index element={<Awaiting_Responses />} />
              <Route path=":id/detail" element={<AwaitingResponseView />} />
            </Route>

            <Route path="/payments" element={<Payments />} />

            <Route path="/loan">
              <Route index element={<Loan />} />
              <Route path=":id/detail" element={<LoanDetail />} />
            </Route>

            <Route path="requests" element={<RequestList />} />

            <Route path="/merchants">
              <Route index element={<Merchents />} />
              <Route path="add" element={<Create />} />
              <Route path=":id/detail" element={<CompanyDetail />} />
              <Route path=":id/edit" element={<Edit />} />
            </Route>

            <Route path="/support">
              <Route index element={<Supports />} />
              <Route path=":id/details" element={<TicketEdit />} />
            </Route>

            <Route path="/contacts">
              <Route index element={<Contact />} />
            </Route>

            <Route path="/roles">
              <Route index element={<Roles />} />
              <Route path="create" element={<CreateRole />} />
              <Route path=":id/edit" element={<EditRole />} />
            </Route>

            <Route path="/coupons">
              <Route index element={<Coupons />} />
              <Route path="add" element={<AddCoupon />} />
            </Route>

            {/* <Route path="/comissions" element={<Comissions />} /> */}
            <Route path="/subscribers" element={<Subcribe_Email />} />

            <Route path="/catalogs">
              <Route index element={<Product_Catalouge />} />
              <Route path="add" element={<AddProductCatalouge />} />
              <Route path="bulk" element={<BulkUpload />} />
              <Route path=":id/detail" element={<CatelougeDetailView />} />
              <Route path=":id/edit" element={<EditProductCatalouge />} />
            </Route>

            <Route path="/categories" element={<PdtCategory />} />

            <Route path="/reports" element={<Reports />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
          {/* </div>
              </div>
            </div>
          </div> */}
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
