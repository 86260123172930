import React, { useEffect, useRef } from "react";
import { useState } from "react";
import LoanData from "./LoanApi";
import LoanTable from "./LoanTable";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navebar from "../navbar";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import toasterUpdate from "../utils/toasterUpdate";
import Pagination from "../component/Pagination";
import { Link } from "react-router-dom";

const RequestList = () => {
  const [data, setData] = useState([]);
  const [openedUser, setOpenedUser] = useState({
    amount: "",
    sanctioned_amount: "",
    id: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("token");

  const searchRef = useRef(null);
  const closeRef = useRef();

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    try {
      const response = await fetch(
        `${baseUrl}/admin/wallet/requests?page=${currentPage}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, data.message, "error");
        return;
      }

      setData(result.data.data);
      setLastPage(result.data.last_page);
      toast.dismiss(toastID);
    } catch (error) {
      toasterUpdate(toastID, error, "error");
    }
  };

  const approveApi = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const { id, amount, sanctioned_amount } = openedUser;
    try {
      const response = await fetch(`${baseUrl}/admin/wallet/${id}/approve`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sanctioned_amount }),
      });
      const result = await response.json();

      console.log(result);

      if (result.error) {
        closeRef.current.click();
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      fetchApi();
      closeRef.current.click();
      toasterUpdate(toastID, result.message, "success");
    } catch (error) {
      toasterUpdate(toastID, error, "error");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  const searchGo = () => {
    setSearch(searchRef.current.value);
  };

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, [search]);

  return (
    <>
      <div className="Main-wrapper">
        <MobileMenu />
        <div className="left_menu">
          <div className="LeftFixedMenu">
            <div className="row">
              <div className="col-12">
                <Navebar />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSideBar2"></div>
        <div className="rightSideBar">
          <div className="row">
            <div className="col-12">
              <div className="row topImgTTL">
                <div className="col-12 d-flex align-items-center pageTTL">
                  <img src="/images/Menu.png" height="20" alt="Menu" />
                  <h3>Sanction Requests</h3>
                </div>
                {/* <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <span
                    className="input-group-text searchBtn"
                    id="basic-addon2"
                  >
                    Search
                  </span>
                </div> */}

                <div className="my-4">
                  <div className="">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Vendors{" "}
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Seller’s</button>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    {/* <div className="dropdown">
                      <button className="btn btn-secondary btn-theme1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">Edit</a></li>
                        <li><a className="dropdown-item" href="#">Delete</a></li>

                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tab-content mt-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="table mt-4 table-responsive loanTable">
                      <table className="table checkBoxTable ">
                        <tr>
                          <th>Sr No.</th>
                          <th>Company</th>
                          <th>Expected Amount</th>
                          <th>Sanctioned Amount</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                        {data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.vendor?.company}</td>
                              <td>{item.amount}</td>
                              <td>{item.sanctioned_amount || "----"}</td>
                              <td>{item.status}</td>
                              <td>
                                <button
                                  className="btn btn-table"
                                  style={{
                                    margin: "0px auto",
                                    width: "80px",
                                    display: "block",
                                  }}
                                  disabled={item.status === "accept"}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={() => setOpenedUser(item)}
                                >
                                  Approval
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>

                    <Pagination
                      handlePageChange={handlePageChange}
                      lastPage={lastPage}
                      currentPage={currentPage}
                    />
                  </div>
                  {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                    <LoanTable data={data} />
                  </div> */}
                </div>
                {/* <OrderTable data={data} /> */}

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="false"
                >
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <form onSubmit={approveApi}>
                        <div className="modal-body">
                          <h4 className=" ms-0 pb-4">Request Approval</h4>
                          <div className="mb-3 form-group">
                            <label for="pass" className="form-label">
                              Expected Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="slug"
                              readOnly
                              defaultValue={openedUser.amount}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Sanctioned Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="slug"
                              onInput={(e) => {
                                setOpenedUser(() => {
                                  return {
                                    ...openedUser,
                                    sanctioned_amount: e.target.value,
                                  };
                                });
                              }}
                              defaultValue={openedUser.sanctioned_amount}
                            />
                          </div>
                        </div>
                        <div
                          className="modal-footer"
                          style={{ borderTop: "unset" }}
                        >
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            data-bs-dismiss="modal"
                            ref={closeRef}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary btn-theme"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestList;
