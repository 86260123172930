const PaymentData = [
    {
        id:1,
        TransactionID:1215353,
        Company:"Shakti trader",
        CreditLimit:10000,
        SanctionedAmount:10000
    },
    {
        id:2,
        TransactionID:1215353,
        Company:"Shakti trader",
        CreditLimit:10000,
        SanctionedAmount:10000
    },
    {
        id:3,
        TransactionID:1215353,
        Company:"Shakti trader",
        CreditLimit:10000,
        SanctionedAmount:10000
    },
    {
        id:4,
        TransactionID:1215353,
        Company:"Shakti trader",
        CreditLimit:10000,
        SanctionedAmount:10000
    },
    {
        id:5,
        TransactionID:1215353,
        Company:"Shakti trader",
        CreditLimit:10000,
        SanctionedAmount:10000
    }
]
export default PaymentData