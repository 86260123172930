import React from "react";
import { Link } from "react-router-dom";

const MerchentTable = ({ api, action }) => {
  const { ids, setIds } = action;

  const storeID = (g_id) => {
    if (ids.includes(g_id)) {
      setIds(ids.filter((i) => i !== g_id));
    } else {
      setIds([...ids, g_id]);
    }
  };
  return (
    <>
      <div className="table table-responsive mt-4">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Company</th>
              <th className="text-justify">Member</th>
              <th>KYC</th>
              <th>Approved</th>
              <th className="text-end">Details</th>
            </tr>
          </thead>

          <tbody>
            {api.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={ids.includes(item.id)}
                      onChange={() => storeID(item.id)}
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.vendor ? item.vendor.company : "null"}</td>
                  <td>
                    <span
                      id={`${item.member && item.member.membership}`}
                      className={`${item.member && item.member.membership}`}
                    >
                      {item.member?.membership}
                    </span>
                  </td>
                  <td className="text-start">
                    {item.is_complete ? (
                      <i
                        className="fas fa-check bg-primary text-white px-3 py-2 rounded-3"
                        title="Kyc completed"
                      ></i>
                    ) : (
                      <i
                        className="fas fa-times bg-danger text-white px-3 py-2 rounded-3"
                        title="kyc not completed"
                      ></i>
                    )}
                  </td>
                  <td className="text-center">
                    {item.approved ? (
                      <i
                        className="fas fa-check bg-primary text-white px-3 py-2 rounded-3"
                        title="profile approved"
                      ></i>
                    ) : (
                      <i
                        className="fas fa-circle-exclamation fs-4 text-danger"
                        title="profile not approved"
                      ></i>
                    )}
                  </td>
                  <td>
                    <Link
                      to={`${item.id}/detail`}
                      className="btn btn-table float-end"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MerchentTable;
